import React, { useRef, useState, useContext, useEffect } from "react";
import AuthContext from "../Context/AuthProvider";
import LeftLogin from "./DesignLogin/LeftLogin";
import { Buffer } from "buffer";
import { sha512 } from "js-sha512";
import logoTop from "../../assets/images/logo/logowhite2.png";
import logoBot from "../../assets/images/logo/logowhite1.png";

import axios from "axios";
import { useParams } from "react-router-dom";
import detectBrowserLanguage from "detect-browser-language";
import Loader from "../Ui/Loader/Loader";

const availableLanguages = ["fr-FR", "en-EN"];

// Fields translations
const fields = {
    title: {
        "fr-FR": "Connexion EE",
        "en-EN": "Login EE",
    },
    lastname: {
        "fr-FR": "Nom",
        "en-EN": "Lastname",
    },
    firstname: {
        "fr-FR": "Prénom",
        "en-EN": "Firstname",
    },
    connection: {
        "fr-FR": "Connexion",
        "en-EN": "Connection",
    },
};

function LoginEE() {
    const [name, setName] = useState("");
    const [firstname, setFirstname] = useState("");
    const [num, setNum] = useState("");
    const [language, setLanguage] = useState(null);

    const { id } = useParams();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = {
            pt: id,
            prenom: firstname,
            nom: name,
        };
        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}/loginEE`,
            data: { data },
            withCredentials: true,
        }).then((res) => {
            if (res.data !== null) {
                window.location = "/pointage-ee/" + id + "/" + res.data;
            }
        });
    };

    useEffect(() => {
        const lang = detectBrowserLanguage();
        console.log(lang);
        if (availableLanguages.includes(lang)) {
            setLanguage(lang);
        } else {
            setLanguage("en-EN");
        }
    }, []);

    return (
        <>
            <div className="d-flex">
                <LeftLogin className="desktop-only" />

                {language === null ? (
                    <div display={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Loader />
                    </div>
                ) : (
                    <section className="login-container">
                        <div className="d-flex flex-column login-logo-container">
                            <img src={logoTop} alt="logo" />
                            <img src={logoBot} alt="logo" />
                        </div>
                        <h1>{fields.title[language]}</h1>
                        <form onSubmit={handleSubmit} className="d-flex flex-column login-form-container">
                            {id === undefined && (
                                <input
                                    placeholder="Num PT"
                                    type="text"
                                    id="num"
                                    onChange={(e) => setNum(e.target.value)}
                                    value={num}
                                    required
                                    style={{ height: "50px", marginBottom: "8px", borderRadius: "5px", width: "100%" }}
                                />
                            )}

                            <input
                                style={{ height: "50px", marginBottom: "8px", borderRadius: "5px", width: "100%" }}
                                placeholder={fields.firstname[language]}
                                type="text"
                                id="firstname"
                                onChange={(e) => setFirstname(e.target.value)}
                                value={firstname}
                                required
                            />

                            <input
                                placeholder={fields.lastname[language]}
                                type="text"
                                id="name"
                                onChange={(e) => setName(e.target.value)}
                                value={name}
                                required
                                style={{ height: "50px", marginBottom: "8px", borderRadius: "5px", width: "100%" }}
                            />
                            <button
                                style={{
                                    height: "50px",
                                    backgroundColor: "#1d5399",
                                    color: "white",
                                    border: "none",
                                    borderRadius: "5px",
                                }}
                            >
                                {fields.connection[language]}
                            </button>
                        </form>
                    </section>
                )}
            </div>
        </>
    );
}

export default LoginEE;

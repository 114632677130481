import React from "react";
import PDFHeader from "../../PDFHeader/PDFHeader";
import { CardRecoPDF } from "./CardRecoPDF";

// Using a class component, everything works without issue
export class RecommandationsPDF extends React.PureComponent {
    componentDidMount() {
        document.getElementById("avant_inter").innerHTML = this.props.reco.avant_intervention;
        document.getElementById("mode_ope").innerHTML = this.props.reco.mode_operatoire;
    }

    render() {
        return (
            <div className="PDFValidateurContent">
                <div className="recommandationsPDF">
                    <h1 className="titrePDF">II - RECOMMANDATIONS</h1>
                    <div className="DescriptionPDFContent">
                        <div style={{ marginLeft: "50px", marginRight: "50px", marginTop: "10px" }}>
                            <table className="table table-bordered table-sm align-middle">
                                <thead className="theadValidation">
                                    <tr>
                                        <th scope="col" colSpan={4} style={{ textAlign: "center" }}>
                                            ORGANISATION DU CHANTIER
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.orga?.parking && (
                                        <tr>
                                            <th scope="row">Parking</th>
                                            <td>{this.props.orga.parking}</td>
                                        </tr>
                                    )}
                                    {this.props.orga?.zone_stockage && (
                                        <tr>
                                            <th scope="row">Stockage</th>
                                            <td>{this.props.orga.zone_stockage}</td>
                                        </tr>
                                    )}
                                    {this.props.orga?.zone_restauration && (
                                        <tr>
                                            <th scope="row">Restauration</th>
                                            <td>{this.props.orga.zone_restauration}</td>
                                        </tr>
                                    )}
                                    {this.props.orga?.zone_travail && (
                                        <tr>
                                            <th scope="row">Zone de travail</th>
                                            <td>{this.props.orga.zone_travail}</td>
                                        </tr>
                                    )}
                                    {this.props.orga?.toilettes && (
                                        <tr>
                                            <th scope="row">Toilettes</th>
                                            <td>{this.props.orga.toilettes}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>

                        <h5 style={{ textAlign: "center", marginTop: "20px" }}>
                            PRÉALABLE A L'INTERVENTION DE L'ENTREPRISE
                        </h5>
                        <div
                            className="d-flex justify-content-evenly"
                            style={{ marginLeft: "50px", marginRight: "50px", marginTop: "20px" }}
                        >
                            <div style={{ width: "50%" }}>
                                <p style={{ fontSize: "1.2em" }}>Avant l'intervention</p>
                                <p id="avant_inter">{this.props.reco.avant_intervention}</p>
                            </div>
                            <div style={{ width: "50%" }}>
                                <p style={{ fontSize: "1.2em" }}>Mode opératoire</p>
                                <p id="mode_ope">{this.props.reco.mode_operatoire}</p>
                            </div>
                        </div>

                        <h4 style={{ textAlign: "center" }}>Organisation des secours</h4>
                        <div
                            className="d-flex justify-content-evenly"
                            style={{ marginLeft: "50px", marginRight: "50px", marginTop: "15px" }}
                        >
                            <div style={{ width: "48%", marginRight: "2%" }}>
                                {this.props.firstKeys.map((k) => {
                                    return <CardRecoPDF orga_sec={this.props.orga_sec[k]} />;
                                })}
                            </div>
                            <div style={{ width: "48%", marginLeft: "2%" }}>
                                {this.props.secondKeys.map((k) => {
                                    return <CardRecoPDF orga_sec={this.props.orga_sec[k]} />;
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sectionSpacing"></div>
            </div>
        );
    }
}

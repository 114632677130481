import React, { Component } from "react";
import MenuContextuel from "../../../MenuContextuel/MenuContextuel";
import MenuContextuelDoc from "../../../MenuContextuel/Documents/MenuContextuelDoc";

class DocGenerate extends Component {
    render() {
        return (
            //ecrire le code ici
            <table class="table">
                <tbody>
                    <tr className="table-active">
                        <th style={{paddingLeft: '40px'}}>Nom du document</th>
                        <td>20/10/2021</td>
                        <td>PDF</td>
                        <td><MenuContextuelDoc /></td>
                    </tr>
                </tbody>
            </table>
        );
    }
}

export default DocGenerate;
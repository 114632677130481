import React, {useContext} from 'react';
import Accueil from "../../components/Accueil/Accueil";
import {UidContext} from "../../components/Context/UidContext";

const Site = () => {
    const uid = useContext(UidContext);
    return (
        <>
            <Accueil />
        </>
    );
};

export default Site;
import React from "react";
import "./HeaderAdmin.css";
import AddUser from "./AddUser/AddUser";

function HeaderAdmin(props) {
    return (
        <div>
            <div className="header_admin">
                <div className="titre_page titre_admin">
                    <h1>{props.titre}</h1>
                </div>
            </div>
        </div>
    );
}

export default HeaderAdmin;

import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

function AddDocument({ pt }) {
    const [modal, setModal] = useState(false);
    const [file, setFile] = useState();

    const toggleModalAddDocument = () => {
        setModal(!modal);
    };

    if (modal) {
        document.body.classList.add("active-modal");
    } else {
        document.body.classList.remove("active-modal");
    }

    function makeid(length) {
        let result = "";
        let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    const handleAddDocument = async () => {
        const data = new FormData();
        data.append("file", file);
        let doc = {};

        if (pt.documents_PT !== null && pt.documents_PT !== "") {
            doc = JSON.parse(pt.documents_PT);
        }

        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}/docs/${pt.id}`,
            data: data,
            withCredentials: true,
        })
            .then(async () => {
                if (doc !== null) {
                    const theKey = "docs" + makeid(10);
                    let object = doc;
                    object[theKey] = {
                        date: new Date().toLocaleDateString("fr-FR", {
                            weekday: "short",
                            day: "2-digit",
                            month: "long",
                            year: "numeric",
                        }),
                        filename: pt.id + file.name,
                        genere: false,
                    };

                    await axios({
                        method: "put",
                        url: `${process.env.REACT_APP_API_URL}/docsInDB/${pt.id}`,
                        data: object,
                        withCredentials: true,
                    })
                        .then(() => {
                            alert("Fichier enregistré");
                            window.location = window.location;
                        })
                        .catch((err) => {
                            console.log(err);
                            alert("Erreur dans l'upload du fichier");
                        });
                } else {
                    const docName = "docs" + makeid(10);
                    const datas = {};
                    datas[docName] = {
                        date: new Date().toLocaleDateString("fr-FR", {
                            weekday: "short",
                            day: "2-digit",
                            month: "long",
                            year: "numeric",
                        }),
                        filename: pt.id + file.name,
                        genere: false,
                    };

                    await axios({
                        method: "put",
                        url: `${process.env.REACT_APP_API_URL}/docsInDB/${pt.id}`,
                        data: datas,
                        withCredentials: true,
                    })
                        .then(() => {
                            alert("Fichier enregistré");
                            window.location = window.location;
                        })
                        .catch((err) => {
                            console.log(err);
                            alert("Erreur dans l'upload du fichier");
                        });
                }
            })
            .catch((err) => {
                console.log(err);
                alert("Erreur dans l'upload du fichier");
            });
    };

    return (
        <div>
            <button onClick={toggleModalAddDocument} className="button_add_validateur">
                Ajouter un document
            </button>

            {modal && (
                <div className="AddValidateur_overlay">
                    <div className="AddValidateur_wrapper">
                        <div className="AddValidateur_modal">
                            <div className="AddValidateur_header">
                                Nouveau document
                                <button type="button" className="modal-close-button" onClick={toggleModalAddDocument}>
                                    X
                                </button>
                            </div>
                            <div className="AddValidateur_content">
                                <input
                                    className="AddValidateur_content_input"
                                    type="file"
                                    capture="environment"
                                    accept="image/*,.pdf"
                                    id="file"
                                    onChange={(e) => setFile(e.target.files[0])}
                                />
                            </div>
                            <button
                                type="button"
                                className="modal_button_imprimer_validateur"
                                onClick={handleAddDocument}
                            >
                                Ajouter le document
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default AddDocument;

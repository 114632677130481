import React, { Component } from "react";
import loginImg from "../../../assets/images/refinery.jpg";
import "./LeftLogin.css";

class LeftLogin extends Component {
    render() {
        return (
            //ecrire le code ici

            <img
                className={this.props.className}
                src={loginImg}
                alt="pt_menu"
                style={{ width: "50%", height: "100vh", objectFit: "cover" }}
            />
        );
    }
}

export default LeftLogin;

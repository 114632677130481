import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { buildPublicFileUrl } from "../../../../../../Utils/utils";

function CardVSPT({ valid }) {
    return (
        <div className="box_vspt">
            <div className="box_vnspt_header">
                {valid.prenom} {valid.nom}
                <div className="box_vnspt_subheader">{valid.entreprise}</div>
            </div>
            <div className="box_vspt_content">
                {valid.date && <div>{new Date(valid.date).toLocaleDateString("fr-FR")}</div>}
                {valid.numerique === 0 ? "Papier" : "Numérique"}
            </div>
            <div className="box_vspt_button">
                <FontAwesomeIcon className="check" icon={faCheck} />
                Signé
            </div>
            {valid.signature && (
                <img src={buildPublicFileUrl(valid.signature)} alt="signature" style={{ width: "100%" }} />
            )}
        </div>
    );
}
export default CardVSPT;

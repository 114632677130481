import React from "react";
import PDFHeaderV from "../../PDFHeaderV/PDFHeader";

// Using a class component, everything works without issue
export class BoutonARPDFV extends React.PureComponent {
    state = {
        mesures: [],
    };

    componentDidMount() {
        const mesures = Object.values(JSON.parse(this.props.risque.mesures_prevention));
        this.setState({ mesures: mesures });
    }

    render() {
        return (
            <>
                {this.state.mesures.map((mesure, index) => {
                    if (index === 0) {
                        return (
                            <tr key={mesure.text}>
                                <td rowSpan={this.state.mesures.length}>
                                    <div>{this.props.risque.titre_risque}</div>
                                    <div style={{ fontSize: "10px" }}>{this.props.risque.description_risque}</div>
                                </td>
                                <td>{mesure.text}</td>
                                <td></td>
                                <td></td>
                            </tr>
                        );
                    }
                    return (
                        <>
                            <tr key={mesure.text}>
                                <td>{mesure.text}</td>
                                <td></td>
                                <td></td>
                            </tr>
                        </>
                    );
                })}
            </>
        );
    }
}

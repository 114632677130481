import { useMemo } from "react";
import { isEmpty } from "../../../Utils/utils";

export default function OptionalRow({ head, values = [] }) {
    const isRowEmpty = useMemo(() => values.every((v) => isEmpty(v)), [values]);

    if (isRowEmpty) {
        return null;
    }

    return <Row head={head} values={values} />;
}

export function Row({ head, values = [] }) {
    return (
        <tr>
            <th scope="row">{head}</th>
            {values.map((v) => {
                return <td>{v}</td>;
            })}
        </tr>
    );
}

import axios from "axios";

export const GET_INTERVENANT = "GET_INTERVENANT";


export const getIntervenant = () => {
    return (dispatch) => {
        return axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}/pt/pointages`,
            withCredentials: true
        })
            .then((res) => {
                dispatch({type : GET_INTERVENANT, payload : res.data})
            })
            .catch((err) => {console.log(err)});
    }
}
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

const ChangeProfilPicture = () => {
    const [file, setFile] = useState();
    const dispatch = useDispatch();
    const userReducer = useSelector((state) => state.userReducer);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = new FormData();
        data.append("name", userReducer.prenom);
        data.append("id", userReducer.id);
        data.append("file", file);

        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}/changePP`,
            data: data,
            withCredentials: true,
        }).then((res) => {
            if (res.data === true) {
                alert("Photo modifiée");
            } else {
                alert("Erreur du serveur");
            }
        });
    };

    return (
        <section className="update-profile-settings-section">
            <h1>Nouvelle photo de profil</h1>
            <form onSubmit={handleSubmit} className="d-flex flex-column" style={{ width: "80%", marginTop: "20px" }}>
                <input
                    type="file"
                    accept=".jpg, .jpeg, .png"
                    style={{ marginBottom: "8px", borderRadius: "5px", width: "100%", height: "auto" }}
                    onChange={(e) => setFile(e.target.files[0])}
                />

                <button
                    style={{
                        backgroundColor: "#1d5399",
                        padding: "20px",
                        color: "white",
                        border: "none",
                        borderRadius: "5px",
                    }}
                    type="submit"
                >
                    Confirmer votre nouvelle image de profil
                </button>
            </form>
        </section>
    );
};

export default ChangeProfilPicture;

import React from 'react';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Login from "../Login/Login";
import LoginEE from "../Login/LoginEE";
import ChangePassword from "../Login/ChangePassword";
import ForgetPassword from "../Login/ForgetPassword";
import DocumentsPT from "../PT/NewPT/Documents/Documents";
import FinChantierPT from "../PT/NewPT/FinChantier/FinChantier";
import Recommandations from "../PT/NewPT/RecommandationsEE/Recommandations";
import IntervenantsPT from "../PT/NewPT/Intervenants/Intervenants";
import ValidationPT from "../PT/NewPT/Validation/Validation";
import AnalyseRisquePT from "../PT/NewPT/AnalyseRisque/AnalyseRisque";
import Description from "../PT/NewPT/Description/Description";
import Clotures from "../PT/Clotures/Clotures";
import Favoris from "../PT/Favoris/Favoris";
import PT from "../PT/PT";
import Site from "../../containers/Site/Site";
import Reglage from "../Reglage/General/General";
import Utilisateurs from '../Admin/Utilisateurs/Utilisateurs';
import RecommandationsAdmin from '../Admin/RecommandationsAdmin/RecommandationsAdmin';
import KPIR from '../Reglage/KPIR/KPIR';
import Aide from '../Reglage/Aide/Aide';
import PointageEE from '../Login/PointageEE';
import Error404 from '../Login/Error404';
import AnalyseRisqueAdmin from '../Admin/AnalyseRisqueAdmin/AnalyseRisqueAdmin';
import PointageEEA from '../Login/PointageEEA';

const Index = ({accepted, refused}) => {
    return (
        <div>
            <Router>
                <Routes>
                    <Route path="/" element={ <Login accepted={accepted} refused={refused}/> } />

                    <Route path="/error404" element={ <Error404 /> } />
                    {/* PARTIE REGLAGE */}
                    <Route path="/reglage" element={ <Reglage /> } />
                    <Route path="/kpir" element={ <KPIR /> } />
                    <Route path="/help" element={ <Aide /> } />
                    {/* FIN REGLAGE */}

                    {/* PARTIE ADMIN */}

                    <Route path="/utilisateurs" element={<Utilisateurs/>}/>
                    <Route path="/recommandations-admin" element={ <RecommandationsAdmin /> } />
                    <Route path="/analyse-risque-admin" element={ <AnalyseRisqueAdmin /> } />
                    <Route path="/api-admin" element={ <Utilisateurs /> } />
                    <Route path="/export-admin" element={ <Utilisateurs /> } />
                    {/* FIN ADMIN */}

                    {/* LOGIN */}
                    <Route path="/login-ee/:id" element={ <LoginEE /> } />
                    <Route path="/login-ee/" element={ <LoginEE /> } />
                    <Route path="/pointage-ee/:pt/:contact" element={ <PointageEE /> } />
                    <Route path="/pointage-eeA" element={ <PointageEEA /> } />
                    <Route path="/new-password" element={ <ChangePassword /> } />
                    <Route path="/forget-password" element={ <ForgetPassword /> } />
                    {/* FIN LOGIN */}

                    {/* PT */}
                    <Route path="/pt/documents/:id" element={ <DocumentsPT /> } />
                    <Route path="/pt/fin-chantier/:id" element={ <FinChantierPT /> } />
                    <Route path="/pt/tournee-chantier/:id" element={ <Recommandations /> } />
                    <Route path="/pt/intervenants/:id" element={ <IntervenantsPT /> } />
                    <Route path="/pt/validation/:id" element={ <ValidationPT /> } />
                    <Route path="/pt/analyse-risque/:id" element={ <AnalyseRisquePT /> } />
                    <Route path="/pt/recommandations/:id" element={ <Recommandations /> } />
                    <Route path="/pt/description/:id" element={ <Description /> } />
                    <Route path="/clotures" element={ <Clotures /> } />
                    <Route path="/favoris" element={ <Favoris /> } />
                    <Route path="/pt" element={ <PT /> } />
                    <Route path="/accueil" element={ <Site /> } />
                    {/* FIN PT */}
                </Routes>
            </Router>
        </div>
    );
};

export default Index;
import React from "react";
import "./DescriptionPDF.css";
import PDFHeader from "../../PDFHeader/PDFHeader";
import OptionalRow from "../../OptionalRow";
import { concatStrings } from "../../../../../Utils/utils";
import OptionalTable from "../../OptionalTable";

// Using a class component, everything works without issue
export class DescriptionPDF extends React.PureComponent {
    render() {
        return (
            <div className="PDFValidateurContent">
                <div className="DescriptionPDF">
                    <h1 className="titrePDF">I - DESCRIPTION</h1>

                    <div className="DescriptionPDFContent">
                        <p style={{ textAlign: "center", fontSize: "1em" }}>
                            NOM DU PERMIS DE TRAVAIL : {this.props.pt.nom_PT}
                        </p>

                        <div style={{ marginLeft: "50px", marginRight: "50px", marginTop: "5px" }}>
                            <OptionalTable
                                columns={["TITRE", "NOM", "MOBILE", "MAIL"]}
                                rows={[
                                    {
                                        head: "Rédacteur du Permis",
                                        values: [
                                            concatStrings(
                                                this.props.eu.contact_RdP?.nomRdP,
                                                this.props.eu.contact_RdP?.prenomRdP
                                            ),
                                            this.props.eu.contact_RdP?.telRdP,
                                            this.props.eu.contact_RdP?.mailRdP,
                                        ],
                                    },
                                    {
                                        head: "DO suppléant 1",
                                        values: [
                                            concatStrings(
                                                this.props.eu.contact_suppleant?.nomSuppleant,
                                                this.props.eu.contact_suppleant?.prenomSuppleant
                                            ),
                                            this.props.eu.contact_suppleant?.telSuppleant,
                                            this.props.eu.contact_suppleant?.mailSuppleant,
                                        ],
                                    },
                                    {
                                        head: "DO suppléant 2",
                                        values: [
                                            concatStrings(
                                                this.props.eu.contact_suppleant2?.nomSuppleant2,
                                                this.props.eu.contact_suppleant2?.prenomSuppleant2
                                            ),
                                            this.props.eu.contact_suppleant2?.telSuppleant2,
                                            this.props.eu.contact_suppleant2?.mailSuppleant2,
                                        ],
                                    },
                                    {
                                        head: "Exploitant",
                                        values: [
                                            concatStrings(
                                                this.props.eu.contact_exploitant?.nomExploitant,
                                                this.props.eu.contact_exploitant?.prenomExploitant
                                            ),
                                            this.props.eu.contact_exploitant?.telExploitant,
                                            this.props.eu.contact_exploitant?.mailExploitant,
                                        ],
                                    },
                                    {
                                        head: "Exploitant 2",
                                        values: [
                                            concatStrings(
                                                this.props.eu.contact_exploitant2?.nomExploitant2,
                                                this.props.eu.contact_exploitant2?.prenomExploitant2
                                            ),
                                            this.props.eu.contact_exploitant2?.telExploitant2,
                                            this.props.eu.contact_exploitant2?.mailExploitant2,
                                        ],
                                    },
                                    {
                                        head: "Éxécutant CdT",
                                        values: [
                                            concatStrings(
                                                this.props.eeCon.contact_CdT?.nomCdT,
                                                this.props.eeCon.contact_CdT?.prenomCdT
                                            ),
                                            this.props.eeCon.contact_CdT?.telCdT,
                                            this.props.eeCon.contact_CdT?.mailCdT,
                                        ],
                                    },
                                    {
                                        head: "Éxécutant CdT ST",
                                        values: [
                                            concatStrings(
                                                this.props.stCon.contact_CdT_ST?.nomCdT_ST,
                                                this.props.stCon.contact_CdT_ST?.prenomCdT_ST
                                            ),
                                            this.props.stCon.contact_CdT_ST?.telCdT_ST,
                                            this.props.stCon.contact_CdT_ST?.mailCdT_ST,
                                        ],
                                    },
                                ]}
                            />
                        </div>

                        <div style={{ marginLeft: "50px", marginRight: "50px", marginTop: "5px" }}>
                            <OptionalTable
                                columns={[{ head: "ENTREPRISE EXTÉRIEURE", colSpan: 2 }]}
                                rows={[
                                    {
                                        head: "Nom de l'EE",
                                        values: [this.props.ee?.nom_EE_PT],
                                    },
                                    {
                                        head: "Site",
                                        values: [this.props.ee?.site_EE_PT],
                                    },
                                    {
                                        head: "Atelier",
                                        values: [this.props.ee?.zone_travail_EE],
                                    },
                                    {
                                        head: "Ligne",
                                        values: [this.props.ee?.ligne_EE],
                                    },
                                    {
                                        head: "Date de la PV/PDT",
                                        values: [this.props.ee?.date_vp_EE],
                                    },
                                    {
                                        head: "Début opérations",
                                        values: [this.props.ee?.date_debut_operations_EE],
                                    },
                                    {
                                        head: "Fin opérations",
                                        values: [this.props.ee?.date_fin_operations_EE],
                                    },
                                ]}
                            />
                        </div>

                        <div style={{ marginLeft: "50px", marginRight: "50px", marginTop: "5px" }}>
                            <OptionalTable
                                columns={[{ head: "ENTREPRISE SOUS-TRAITANTE", colSpan: 2 }]}
                                rows={[
                                    {
                                        head: "Nom de l'ES",
                                        values: [this.props.st?.nom_ST_PT],
                                    },
                                    {
                                        head: "Site",
                                        values: [this.props.st?.site_ST_PT],
                                    },
                                    {
                                        head: "Atelier",
                                        values: [this.props.st?.zone_travail_ST],
                                    },
                                    {
                                        head: "Ligne",
                                        values: [this.props.st?.ligne_ST],
                                    },
                                    {
                                        head: "Date de la PV/PDT",
                                        values: [this.props.st?.date_vp_ST],
                                    },
                                    {
                                        head: "Début opérations",
                                        values: [this.props.st?.date_debut_operations_ST],
                                    },
                                    {
                                        head: "Fin opérations",
                                        values: [this.props.st?.date_fin_operations_ST],
                                    },
                                ]}
                            />
                        </div>

                        <div style={{ marginLeft: "50px", marginRight: "50px", marginTop: "5px" }}>
                            <h5 style={{ textAlign: "center" }}>
                                DESCRIPTION DES DIFFÉRENTES PHASES DE L'OPÉRATION A RÉALISER
                            </h5>
                            <p style={{ marginTop: "10px" }}>{this.props.pt.description_phase_PT}</p>
                        </div>
                    </div>
                    <div className="sectionSpacing"></div>
                </div>
            </div>
        );
    }
}

import React, { useEffect, useState } from "react";
import "./Recommandations.css";
import Navbar from "../../NavBar/Navbar";
import NavbarNewPT from "../NavbarNewPT/NavbarNewPT";
import HeaderNewPT from "../HeaderNewPT/HeaderNewPT";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPt } from "../../../../Redux/actions/pt.actions";
import CardRecommandations from "./CardRecommandations";
import { isEmpty } from "../../../../Utils/utils";
import { getUser } from "../../../../Redux/actions/user.actions";

function Recommandation() {
    const { id } = useParams();

    const ptReducer = useSelector((state) => [state.ptReducer]);
    const userReducer = useSelector((state) => state.userReducer);
    const dispatch = useDispatch();

    const [pt, setPt] = useState({});
    const [loading, setLoading] = useState(true);

    const fetchPt = async () => {
        const id_usine = userReducer.id_hash_usine;
        await dispatch(getPt(id_usine));
    };

    const setThePt = () => {
        ptReducer[0].map((ptRed) => {
            if (ptRed.id.toString() === id) {
                setPt(ptRed);
            }
        });
    };

    useEffect(() => {
        if (loading) {
            if (userReducer.id !== undefined) {
                fetchPt();
                setLoading(false);
            }
        }
        if (!isEmpty(ptReducer[0])) {
            setThePt();
        }
    }, [ptReducer, userReducer, loading]);

    const preventRefresh = (e) => {
        e.preventDefault();
    };

    return (
        <div>
            <Navbar />
            <NavbarNewPT id={id} />
            <HeaderNewPT titre="Recommandations EE" />

            <CardRecommandations pt={pt} preventRefresh={preventRefresh} />
        </div>
    );
}

export default Recommandation;

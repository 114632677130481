import React, { useEffect, useRef, useState } from "react";
import "./ImprimerVierge.css";
import { ComponentToPrintV } from "../../PDF/PDFVierge/PDFPTV/ComponentToPrint";
import { useReactToPrint } from "react-to-print";
import { getCurrentRecommandation } from "../../../../Utils/api/recommandation.api";
import { getCurrentRisques } from "../../../../Utils/api/risques.api";

function ImprimerVierge() {
    const [isLoaded, setIsLoaded] = useState(false);
    const [recommandation, setRecommandation] = useState(null);
    const [risques, setRisques] = useState(null);

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle:
            "@page { size: auto;  margin: 20mm; } @media print { body { -webkit-print-color-adjust: exact; print-color-adjust: exact; } }",
    });

    useEffect(() => {
        Promise.allSettled([getCurrentRecommandation(), getCurrentRisques()]).then((values) => {
            const recommandationResult = values[0];
            const risquesResult = values[1];
            if (recommandationResult.status === "fulfilled") {
                setRecommandation(recommandationResult.value.data);
            }
            if (risquesResult.status === "fulfilled") {
                setRisques(risquesResult.value.data);
            }
            setIsLoaded(true);
        });
    }, []);

    return (
        <div>
            {isLoaded && recommandation && risques && (
                <>
                    <ComponentToPrintV
                        ref={componentRef}
                        avant_intervention={recommandation.avant_intervention}
                        mode_operatoire={recommandation.mode_operatoire}
                        orga_secours={Object.values(JSON.parse(recommandation.orga_secours))}
                        risques={risques}
                    />
                    <button onClick={handlePrint} style={{ marginRight: "20px" }} className="bouton_print_header">
                        Imprimer Vierge
                    </button>
                </>
            )}
        </div>
    );
}

export default ImprimerVierge;

import React from "react";
import { CardRecoPDF } from "../../../PDFPT/Recommandations/CardRecoPDF";
import PDFHeaderV from "../../PDFHeaderV/PDFHeader";

// Using a class component, everything works without issue
export class RecommandationsPDF extends React.PureComponent {
    state = {
        orga_secours_first: this.props.orga_secours.splice(0, this.props.orga_secours.length / 2),
        orga_secours_second: this.props.orga_secours.splice(
            this.props.orga_secours.length / 2,
            this.props.orga_secours.length
        ),
    };

    render() {
        return (
            <div className="PDFValidateurContent">
                <div className="recommandationsPDF">
                    <h1 className="titrePDF">II - RECOMMANDATIONS</h1>
                    <div className="DescriptionPDFContent">
                        <div style={{ marginLeft: "50px", marginRight: "50px", marginTop: "10px" }}>
                            <table className="table table-bordered table-sm align-middle">
                                <thead className="theadValidation">
                                    <tr>
                                        <th scope="col" colSpan={4} style={{ textAlign: "center" }}>
                                            ORGANISATION DU CHANTIER
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row" style={{ width: "106px" }}>
                                            Parking
                                        </th>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Stockage</th>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Restauration</th>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Zone de travail</th>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Toilettes</th>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <h5 style={{ textAlign: "center", marginTop: "20px" }}>
                            PRÉALABLE A L'INTERVENTION DE L'ENTREPRISE
                        </h5>
                        <div
                            className="d-flex justify-content-evenly"
                            style={{ marginLeft: "50px", marginRight: "50px", marginTop: "20px" }}
                        >
                            <div style={{ width: "50%" }}>
                                <p style={{ fontSize: "1.2em" }}>Avant l'intervention</p>
                                <p
                                    id="avant_inter"
                                    dangerouslySetInnerHTML={{ __html: this.props.avant_intervention }}
                                ></p>
                            </div>
                            <div style={{ width: "50%" }}>
                                <p style={{ fontSize: "1.2em" }}>Mode opératoire</p>
                                <p
                                    id="mode_ope"
                                    dangerouslySetInnerHTML={{ __html: this.props.avant_intervention }}
                                ></p>
                            </div>
                        </div>

                        <h4 style={{ textAlign: "center", marginTop: "16px" }}>Organisation des secours</h4>
                        <div
                            className="d-flex justify-content-evenly"
                            style={{ marginLeft: "50px", marginRight: "50px", marginTop: "15px" }}
                        >
                            <div style={{ width: "48%", marginRight: "2%" }}>
                                {this.state.orga_secours_first.map((orga) => {
                                    return <CardRecoPDF key={orga.titre} orga_sec={orga} />;
                                })}
                            </div>
                            <div style={{ width: "48%", marginLeft: "2%" }}>
                                {this.state.orga_secours_second.map((orga) => {
                                    return <CardRecoPDF key={orga.titre} orga_sec={orga} />;
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sectionSpacing"></div>
            </div>
        );
    }
}

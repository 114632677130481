import React, { useRef, useState } from "react";
import "./ImprimerValidateur.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import PDFIntervenants from "../../../../../PDF/PDFIntervenants/PDFIntervenants";
import { ComponentToPrint } from "../../../../../PDF/PDFIntervenants/ComponentToPrint";
import { useReactToPrint } from "react-to-print";
import { printPageStyle } from "../../../../../../../Utils/utils";

function ImprimerIntervenants({ date, eu, pt, intervenants, contacts, signature }) {
    // const [modal, setModal] = useState(false);

    // const toggleModalImprimerValidateur = () => {
    //     setModal(!modal);
    // };

    // if(modal) {
    //     document.body.classList.add('active-modal')

    // } else {
    //     document.body.classList.remove('active-modal')
    // }

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        pageStyle: printPageStyle,
        content: () => componentRef.current,
    });

    return (
        <div>
            <button onClick={handlePrint} className="button_imprimer_validateur" style={{ zIndex: 0 }}>
                <FontAwesomeIcon icon={faPrint} />
                Imprimer
            </button>
            <ComponentToPrint
                ref={componentRef}
                date={date}
                eu={eu}
                pt={pt}
                intervenants={intervenants}
                contacts={contacts}
                signature={signature}
            />

            {/*
            {modal && (
                <div className="ImprimerValidateur_overlay">
                    <div className="ImprimerValidateur_wrapper">
                        <div className="ImprimerValidateur_modal">
                            <div className="ImprimerValidateur_header">
                                Imprimer
                                <button
                                    type="button"
                                    className="modal-close-button"
                                    onClick={toggleModalImprimerValidateur}
                                >
                                    X
                                </button>
                            </div>


                            <PDFIntervenants date={date} eu={eu} pt={pt} intervenants={intervenants} contacts={contacts}/>
                        </div>
                    </div>


                </div>
            )} */}
        </div>
    );
}

export default ImprimerIntervenants;

import React, { useEffect, useState } from "react";

const CardCheckBox = ({ object, k, handleCheck, disabled }) => {
    const [euChecked, setEUChecked] = useState(false);
    const [eeChecked, setEEChecked] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (object.ee === "1") {
            setEEChecked(true);
        }

        if (object.eu === "1") {
            setEUChecked(true);
        }

        setLoading(false);
    }, []);

    const handleSetChecked = (e) => {
        let tmp = object;
        if (e.target.checked) {
            tmp[e.target.value] = "1";
        } else {
            tmp[e.target.value] = "0";
        }
        handleCheck(k, tmp);
    };

    return (
        <div style={{}}>
            <div className="checkboxs-align" style={{ marginBottom: "8px" }}>
                {!loading && (
                    <>
                        <input
                            style={{ marginRight: "60px" }}
                            type="checkbox"
                            id="inlineCheckbox1"
                            value="eu"
                            onChange={(e) => handleSetChecked(e)}
                            defaultChecked={euChecked}
                            disabled={disabled}
                            className="custom-checkbox"
                        />
                        <input
                            type="checkbox"
                            id="inlineCheckbox2"
                            value="ee"
                            onChange={(e) => handleSetChecked(e)}
                            defaultChecked={eeChecked}
                            disabled={disabled}
                            className="custom-checkbox"
                        />
                    </>
                )}
                <p className="form-check-label" style={{ width: "350px", paddingLeft: "20px" }}>
                    {object.text}
                </p>
            </div>
        </div>
    );
};

export default CardCheckBox;

import React, { useEffect, useState } from "react";
import "./BoutonAR.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import CardCheckBox from "./CardCheckBox";
import Badge from "../../../../Ui/Badge/Badge";

function BoutonAR({
    titreAR,
    descriptionAR,
    mesure_prevention,
    handleSetData,
    disabled,
}) {
    const [show, setShow] = useState(false);
    const [modal, setModal] = useState(false);
    const [checkedCount, setCheckedCount] = useState(0);

    const toggleModalAR = () => {
        setModal(!modal);
        setShow(!show);
    };

    const [bigJ, setBigJ] = useState({});
    const [keys, setKeys] = useState([]);

    const computeCheckedCount = (json) => {
        return Object.values(json).filter((value) => {
            return value.ee === "1" || value.eu === "1";
        }).length;
    };

    const handleCheck = (key, value) => {
        const object = bigJ;
        object[key] = value;
        setBigJ(object);
        setCheckedCount(computeCheckedCount(object));
        handleSetData(titreAR, bigJ);
    };

    useEffect(() => {
        const bigJSON = JSON.parse(mesure_prevention);
        setBigJ(bigJSON);
        setCheckedCount(computeCheckedCount(bigJSON));

        setKeys(Object.keys(bigJSON));
    }, []);

    return (
        <div>
            <button onClick={toggleModalAR} className="ARButton">
                {titreAR}{" "}
                <div style={{ display: "flex" }}>
                    <Badge style={{ margin: "0px 10px", width: "30px" }}>
                        {checkedCount}
                    </Badge>
                    {!modal ? (
                        <>
                            <FontAwesomeIcon icon={faArrowDown} />
                        </>
                    ) : (
                        <>
                            <FontAwesomeIcon icon={faArrowRight} />
                        </>
                    )}
                </div>
            </button>

            {show ? (
                <div style={{ width: "500px", maxWidth: "90vw" }}>
                    <p style={{ fontWeight: "bold", marginLeft: "2%" }}>
                        {descriptionAR}
                    </p>
                    <div className="d-flex" style={{ paddingLeft: "2%" }}>
                        <div
                            className="name_newpt"
                            style={{ marginRight: "40px" }}
                        >
                            EU
                        </div>
                        <div className="name_newpt">EE/ES</div>
                    </div>
                    {keys.map((k) => {
                        return (
                            <CardCheckBox
                                key={k}
                                object={bigJ[k]}
                                k={k}
                                handleCheck={handleCheck}
                                descriptionAR={descriptionAR}
                                disabled={disabled}
                            />
                        );
                    })}
                </div>
            ) : null}
        </div>
    );
}

export default BoutonAR;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { getCurrentUsersByHabilitation } from "../../../../Utils/api/users.api";
import MenuContextuelDoc from "../../MenuContextuel/Documents/MenuContextuelDoc";
import { faBars } from "@fortawesome/free-solid-svg-icons";

const CardDocs = ({ document, documents, isGenerated, hasHabilitation, displayCheckbox, onCheck, isChecked }) => {
    const [modal, setModal] = useState(false);
    const [extension, setExtension] = useState("");
    const [loading, setLoading] = useState(true);
    const [habilitedUsers, setHabilitedUsers] = useState(null);

    const [name, setName] = useState("");

    useEffect(() => {
        if (modal) {
            if (document.body) document.body.classList.add("active-modal");
        } else {
            if (document.body) document.body.classList.remove("active-modal");
        }
    }, [modal, document]);

    useEffect(() => {
        if (document.filename.includes("pdf")) {
            setExtension("PDF");
        } else if (document.filename.includes("jpg")) {
            setExtension("JPG");
        } else if (document.filename.includes("png")) {
            setExtension("PNG");
        }
        let myRegexp = /\d+(.+)/g;
        let match = myRegexp.exec(document.filename);
        if (match && match[1]) {
            setName(match[1]);
        }
        setLoading(false);
    }, [document, setName, setExtension, setLoading]);

    useEffect(() => {
        if (!hasHabilitation && document.habilitation !== undefined) {
            getCurrentUsersByHabilitation(document.habilitation)
                .then((axios) => {
                    setHabilitedUsers(axios.data);
                })
                .catch((e) => {});
        } else {
            setHabilitedUsers(null);
        }
    }, []);

    return (
        <>
            <table className="table" style={{ width: "90%" }}>
                {!loading && (
                    <>
                        <tbody>
                            <tr className="table-active">
                                <td style={{ width: "20%" }}>
                                    <div style={{ display: "flex", alignItems: "center", marginLeft: "8px" }}>
                                        {displayCheckbox && (
                                            <input
                                                className="form-check-input"
                                                style={{ marginRight: "8px", minWidth: "16px" }}
                                                id="checkbox"
                                                name="select-document"
                                                type="checkbox"
                                                onChange={(e) => onCheck(e.target.checked)}
                                                defaultChecked={isChecked}
                                            />
                                        )}
                                        {isGenerated ? (
                                            <div style={{ flex: 1 }}>{document.filename}</div>
                                        ) : (
                                            <div style={{ flex: 1 }}>{name || document.filename}</div>
                                        )}
                                    </div>
                                </td>
                                <td style={{ width: "20%", marginLeft: "2.5%", textAlign: "center" }}>
                                    {document.date}
                                </td>
                                <td style={{ width: "20%", marginLeft: "2.5%", textAlign: "center" }}>{extension}</td>
                                <td style={{ width: "20%", marginLeft: "2.5%", textAlign: "center" }}>
                                    {habilitedUsers === null ? (
                                        <MenuContextuelDoc
                                            doc={document}
                                            documents={documents}
                                            name={name}
                                            isGenerated={isGenerated}
                                        />
                                    ) : (
                                        <button onClick={() => setModal(!modal)} className="bouton_mc reset-button">
                                            <FontAwesomeIcon icon={faBars} />
                                        </button>
                                    )}
                                </td>
                            </tr>
                        </tbody>
                    </>
                )}
            </table>
            {modal && habilitedUsers !== null && (
                <div className="ImprimerValidateur_overlay">
                    <div className="ImprimerValidateur_wrapper">
                        <div className="ImprimerValidateur_modal" style={{ justifyContent: "start" }}>
                            <div className="ImprimerValidateur_header">
                                Utilisateurs habilités
                                <button type="button" className="modal-close-button" onClick={() => setModal(!modal)}>
                                    X
                                </button>
                            </div>

                            <div style={{ alignSelf: "center", marginTop: "16px" }}>
                                <h3 style={{ textAlign: "center" }}>
                                    Vous n'avez pas l'habilitation requise pour visualiser ce document.
                                </h3>
                                {habilitedUsers.length > 0 && (
                                    <>
                                        <h4 style={{ textAlign: "center", marginTop: "48px" }}>
                                            Les utilisateurs suivants ont l'habilitation requise pour visualiser ce
                                            document :
                                        </h4>
                                        <div style={{ textAlign: "center", marginTop: "48px" }}>
                                            {habilitedUsers.map((habilitedUser) => {
                                                return (
                                                    <h5 key={habilitedUser.prenom}>
                                                        {habilitedUser.nom} {habilitedUser.prenom}
                                                    </h5>
                                                );
                                            })}
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default CardDocs;

import React, { useEffect, useState } from "react";
import "./KPIR.css";
import NavbarReglage from "../NavbarReglage/NavbarReglage";
import HeaderReglage from "../HeaderReglage/HeaderReglage";
import { useSelector } from "react-redux";
import axios from "axios";

function KPIR(props) {
    const userReducer = useSelector((state) => state.userReducer);

    const [pt, setPt] = useState(false);
    const [pp, setPp] = useState(false);
    const [coactivite, setCoactivite] = useState(false);
    const [loading, setLoading] = useState(true);

    const setInitialValues = async () => {
        const kpis = await JSON.parse(userReducer.kpi);
        setPt(kpis.pt);
        setPp(kpis.pp);
        setCoactivite(kpis.coactivite);
        setLoading(false);
    };

    const handleSave = async () => {
        const kpis = {
            pp: pp,
            pt: pt,
            coactivite: coactivite,
        };

        console.log(kpis);

        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}/kpi/${userReducer.id}`,
            data: { kpi: kpis },
            withCredentials: true,
        }).then((res) => {
            if (res.data === true) {
                alert("KPIs sauvegardées");
            } else {
                alert("Impossible de sauvegarder, le serveur rencontre un problème");
            }
        });
    };

    useEffect(() => {
        if (loading) {
            if (userReducer.id !== undefined) {
                if (userReducer.kpi !== null && userReducer.kpi !== undefined) {
                    setInitialValues();
                } else {
                    setLoading(false);
                }
            }
        }
    }, [userReducer]);

    return (
        <div>
            <NavbarReglage />
            <HeaderReglage titre="KPI" />

            <div className="content_KPIR">
                <table className="table table-borderless" style={{ color: "grey", border: "none" }}>
                    <thead>
                        <tr className="tableau_header">
                            <th style={{ width: "50%" }}>NOM</th>
                            <th style={{ width: "50%" }} id="numeroPT">
                                AFFICHER
                            </th>
                        </tr>
                    </thead>

                    {!loading && (
                        <tbody>
                            <tr className="tableau_content" style={{ marginTop: "40px" }}>
                                <th style={{ width: "50%", overflow: "hidden", textOverflow: "ellipsis" }}>
                                    PT ouverts dont je suis le rédacteur
                                </th>
                                <td
                                    style={{
                                        width: "50%",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                    }}
                                >
                                    <input
                                        type="checkbox"
                                        defaultChecked={pt}
                                        onChange={(e) => setPt(e.target.checked)}
                                    />
                                </td>
                            </tr>
                            <tr className="tableau_content">
                                <th style={{ width: "50%", overflow: "hidden", textOverflow: "ellipsis" }}>
                                    PP ouverts dont je suis le rédacteur
                                </th>
                                <td
                                    style={{
                                        width: "50%",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                    }}
                                >
                                    <input
                                        type="checkbox"
                                        defaultChecked={pp}
                                        onChange={(e) => setPp(e.target.checked)}
                                    />
                                </td>
                            </tr>
                            <tr className="tableau_content">
                                <th style={{ width: "50%", overflow: "hidden", textOverflow: "ellipsis" }}>
                                    Coactivité
                                </th>
                                <td
                                    style={{
                                        width: "50%",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                    }}
                                >
                                    <input
                                        type="checkbox"
                                        defaultChecked={coactivite}
                                        onChange={(e) => setCoactivite(e.target.checked)}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    )}
                </table>
                <button
                    className="buttonValidationI btn btn-success save-button-KPIR"
                    style={{ marginTop: "30px", width: "160px" }}
                    onClick={handleSave}
                >
                    Sauvegarder
                </button>
            </div>
        </div>
    );
}

export default KPIR;

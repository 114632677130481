import axios from 'axios';

export const GET_USERS = "GET_USERS";
export const USER_INACTIVE = "USER_INACTIVE";
export const NEW_USER = "NEW_USER";
export const UPDATE_USER = "UPDATE_USER";

export const getUsers = () => {
    return (dispatch) => {
        return axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}/users`,
            withCredentials: true
        })
            .then((res) => {
                dispatch({type : GET_USERS, payload : res.data})
            })
            .catch((err) => {console.log(err)});
    }
}

export const setUserInactive = (id) => {
    return (dispatch) => {
        return axios({
            method : "put",
            url : `${process.env.REACT_APP_API_URL}/user-inactive/${id}`,
            withCredentials : true
        })
            .then((res) => {
                dispatch({type : USER_INACTIVE, payload : {id}})
            })
            .catch((err) => {
                console.log(err);
            })
    }
}

export const newUser = (data) => {
    return (dispatch) => {
        return axios({
            method : "post",
            url : `${process.env.REACT_APP_API_URL}/new-user`,
            data : {data},
            withCredentials : true
        })
            .then((res) => {
                dispatch({type : NEW_USER, payload : {res}})
            })
            .catch((err) => {
                console.log(err);
            })
    }
}

export const updateUser = (data, id) => {
    return (dispatch) => {
        return axios({
            method : "put",
            url : `${process.env.REACT_APP_API_URL}/update-user/${id}`,
            data : {data},
            withCredentials : true
        })
            .then((res) => {
                dispatch({type : UPDATE_USER, payload : {data, id}})
            })
    }
}
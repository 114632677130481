import axios from "axios";

const routesPrefix = `${process.env.REACT_APP_API_URL}/signature/`;

export async function addSignatureValidation(id, file, validator, validatorKey, factoryId, numPt) {
    const formData = new FormData();
    formData.append("validator", JSON.stringify(validator));
    formData.append("validatorKey", validatorKey);
    formData.append("factoryId", factoryId);
    formData.append("numPt", numPt);
    formData.append("file", file);

    try {
        const response = await axios({
            method: "post",
            url: `${routesPrefix}${id}/validation`,
            data: formData,
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

export async function addSignatureEnd(id, file, validator, validatorKey, factoryId, numPt) {
    const formData = new FormData();
    formData.append("validator", JSON.stringify(validator));
    formData.append("validatorKey", validatorKey);
    formData.append("factoryId", factoryId);
    formData.append("numPt", numPt);
    formData.append("file", file);

    try {
        const response = await axios({
            method: "post",
            url: `${routesPrefix}${id}/end`,
            data: formData,
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

export async function addSignatureIntervenant(id, file, validator, validationDate, factoryId, numPt) {
    const formData = new FormData();
    formData.append("validator", JSON.stringify(validator));
    formData.append("validationDate", validationDate);
    formData.append("factoryId", factoryId);
    formData.append("numPt", numPt);
    formData.append("file", file);

    try {
        const response = await axios({
            method: "post",
            url: `${routesPrefix}${id}/intervenant`,
            data: formData,
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

export async function getDailyValidations(id) {
    try {
        const response = await axios({
            method: "get",
            url: `${routesPrefix}${id}/daily-validations`,
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

import React from "react";
import "./HeaderReglage.css";

function HeaderReglage(props) {
    return (
        <div>
            <div className="header_reglage">
                <div className="titre_page titre_reglage">
                    <h1>{props.titre}</h1>
                </div>
            </div>
        </div>
    );
}

export default HeaderReglage;

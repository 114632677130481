import React, { useEffect, useMemo, useState } from "react";
import { hashFactory } from "../../../../Utils/constant/factoryHash";
import { restrictedRiskAnalysis } from "../../../../Utils/constant/restrictedRiskAnalysis";

const CardCheckBox = ({ object, handleEditText, handleDelete, idUsine }) => {
    const [showEdit, setShowEdit] = useState(false);
    const [text, setText] = useState(object.text);

    const canEdit = useMemo(() => {
        if (idUsine) {
            return !restrictedRiskAnalysis[hashFactory[idUsine]].some((restrictedRiskAnalysisText) =>
                text.trim().includes(restrictedRiskAnalysisText)
            );
        }
    }, [idUsine, text]);

    const handleEdit = async () => {
        if (!showEdit) {
            setShowEdit(!showEdit);
        } else {
            handleEditText(object, text);
            setShowEdit(!showEdit);
        }
    };

    const handleDeleteText = () => {
        if (window.confirm("Attention la suppression est irréversible. Êtes-vous sûr ?")) {
            handleDelete(object.text);
        }
    };

    return (
        <div
            style={{
                marginTop: "5%",
                backgroundColor: "#e1e1e1",
                borderStyle: "solid",
                borderColor: "black",
                borderWidth: "1px",
                padding: "2%",
            }}
        >
            {!showEdit ? (
                <p className="form-check-label" style={{ width: "350px", paddingLeft: "20px" }}>
                    {object.text}
                </p>
            ) : (
                <>
                    <textarea
                        className="form-check-label"
                        style={{ width: "350px", paddingLeft: "20px" }}
                        defaultValue={object.text}
                        onChange={(e) => setText(e.target.value)}
                    ></textarea>
                    <br />
                </>
            )}
            {canEdit && (
                <>
                    <button style={{ marginLeft: "3%" }} onClick={handleEdit}>
                        {showEdit ? "Valider" : "Editer"}
                    </button>
                    <button style={{ marginLeft: "2%" }} onClick={handleDeleteText}>
                        Supprimer
                    </button>
                </>
            )}
        </div>
    );
};

export default CardCheckBox;

import React from "react";
import PDFHeader from "../../PDFHeader/PDFHeader";
import { CardCommentairePDF } from "./CardCommentairePDF";
import { CardValidPDF } from "../Validation/CardValidPDF";

// Using a class component, everything works without issue
export class FinChantierPDF extends React.PureComponent {
    render() {
        return (
            <div className="PDFValidateurContent">
                <div className="recommandationsPDF">
                    <h1 className="titrePDF">V - FIN DE CHANTIER</h1>

                    <div style={{ marginLeft: "50px", marginRight: "50px", marginTop: "10px" }}>
                        <table className="table table-bordered table-sm align-middle">
                            <thead className="theadValidation">
                                <tr>
                                    <th scope="col">TITRE</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">Travaux terminés</th>
                                    <td>
                                        {this.props.avisChantier !== null
                                            ? this.props.avisChantier?.travauxTermines === true
                                                ? "OK"
                                                : "NOK"
                                            : "NOK"}
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">
                                        Propreté : Zone de travaux propre et rangée. Tri des déchets respectés.
                                    </th>
                                    <td>
                                        {this.props.avisChantier !== null
                                            ? this.props.avisChantier?.proprete === true
                                                ? "OK"
                                                : "NOK"
                                            : "NOK"}
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">Permis spécifiques cloturés</th>
                                    <td>
                                        {this.props.avisChantier !== null
                                            ? this.props.avisChantier?.permis === true
                                                ? "OK"
                                                : "NOK"
                                            : "NOK"}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    {this.props.commentaires ? (
                        <>
                            <div style={{ marginLeft: "50px", marginRight: "50px" }}>
                                <table className="table table-bordered table-sm align-middle">
                                    <thead className="theadValidation">
                                        <tr>
                                            <th scope="col">DATE</th>
                                            <th scope="col">COMMENTAIRES</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.keys(this.props.commentaires).map((com) => {
                                            return <CardCommentairePDF commentaire={this.props.commentaires[com]} />;
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </>
                    ) : (
                        <p style={{ fontStyle: "italic", textAlign: "center", marginTop: "10px" }}>
                            Il n'y a pas encore de commentaire sur ce permis de travail.
                        </p>
                    )}

                    {this.props.signatairesFin ? (
                        <>
                            <p style={{ fontStyle: "italic", textAlign: "center", marginTop: "10px" }}>
                                Liste des validateurs ayant signé numériquement ou manuellement la fin de chantier :
                            </p>
                            <div style={{ marginLeft: "50px", marginRight: "50px", marginTop: "10px" }}>
                                <table className="table table-bordered table-sm align-middle">
                                    <thead className="theadValidation">
                                        <tr>
                                            <th scope="col">VALIDATEUR</th>
                                            <th scope="col">DATE</th>
                                            <th scope="col">TYPE</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.keys(this.props.signatairesFin).map((valid) => {
                                            if (this.props.signatairesFin[valid].prenom !== "") {
                                                return (
                                                    <CardValidPDF
                                                        validateur={this.props.signatairesFin[valid]}
                                                        k={valid}
                                                    />
                                                );
                                            }
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </>
                    ) : (
                        <p style={{ fontStyle: "italic", textAlign: "center", marginTop: "10px" }}>
                            Il n'y a pas encore de validateur ayant signé la fin du chantier.
                        </p>
                    )}
                </div>
            </div>
        );
    }
}

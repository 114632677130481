import {GET_USINES} from "../actions/usine.actions";

const initialState = {};

export default function usineReducer( state = initialState, action) {

    switch (action.type) {
        case GET_USINES:
            return action.payload;

        default:
            return state;
    }
}
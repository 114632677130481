import React from "react";
import "./DescriptionPDF.css";
import PDFHeaderV from "../../PDFHeaderV/PDFHeader";

// Using a class component, everything works without issue
export class DescriptionPDF extends React.PureComponent {
    render() {
        return (
            <div className="PDFValidateurContent">
                <div className="DescriptionPDF">
                    <h1 className="titrePDF">I - DESCRIPTION</h1>

                    <div className="DescriptionPDFContent">
                        <p style={{ textAlign: "left", marginLeft: "50px", fontSize: "1em" }}>
                            NOM DU PERMIS DE TRAVAIL :{" "}
                        </p>

                        <div style={{ marginLeft: "50px", marginRight: "50px", marginTop: "5px" }}>
                            <table className="table table-bordered table-sm align-middle">
                                <thead className="theadValidation">
                                    <tr>
                                        <th scope="col" style={{ width: "140px" }}>
                                            TITRE
                                        </th>
                                        <th scope="col" style={{ width: "130px" }}>
                                            NOM
                                        </th>
                                        <th scope="col">TELEPHONE</th>
                                        <th scope="col" style={{ width: "150px" }}>
                                            MAIL
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row">Rédacteur du Permis</th>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">DO suppléant 1</th>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">DO suppléant 2</th>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Exploitant</th>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Exploitant 2</th>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Chargé d'affaire EE</th>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Chargé d'affaire ES</th>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div style={{ marginLeft: "50px", marginRight: "50px", marginTop: "5px" }}>
                            <table className="table table-bordered table-sm align-middle">
                                <thead className="theadValidation">
                                    <tr>
                                        <th scope="col" colSpan={4} style={{ textAlign: "center" }}>
                                            ENTREPRISE EXTÉRIEURE
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row" style={{ width: "140px" }}>
                                            Nom de l'EE
                                        </th>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Site</th>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Atelier</th>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Ligne</th>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Date de la PV/PDT</th>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Début opérations</th>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Fin opérations</th>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div style={{ marginLeft: "50px", marginRight: "50px", marginTop: "5px" }}>
                            <table className="table table-bordered table-sm align-middle">
                                <thead className="theadValidation">
                                    <tr>
                                        <th scope="col" colSpan={4} style={{ textAlign: "center" }}>
                                            ENTREPRISE SOUS-TRAITANTE
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row" style={{ width: "140px" }}>
                                            Nom de l'ES
                                        </th>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Site</th>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Atelier</th>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Ligne</th>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Date de la PV/PDT</th>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Début opérations</th>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Fin opérations</th>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div
                            style={{
                                marginLeft: "50px",
                                marginRight: "50px",
                                marginTop: "5px",
                                height: "145px",
                            }}
                            className="description-steps-field"
                        >
                            <h5 style={{ textAlign: "center", padding: "8px 12px", fontSize: "1.2em" }}>
                                DESCRIPTION DES DIFFÉRENTES PHASES DE L'OPÉRATION A RÉALISER
                            </h5>
                        </div>
                    </div>
                    <div className="pagebreak"></div>
                </div>
            </div>
        );
    }
}

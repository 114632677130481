import React, {useEffect, useState} from 'react';
import {setModeOpeRecommandation} from "../../../Redux/actions/recommandation.actions";

const CardRecoAdmin = ({text, i, handleChange, handleDeleteOrga}) => {

    const [id, setId] = useState(0);
    const [loading, setLoading] = useState(true);
    const [showEdit, setShowEdit] = useState(false);
    const [titre, setTitre] = useState(text.titre);
    const [texte, setTexte] = useState(text.text)

    useEffect(() => {
        if(loading) {
            setId(Math.random());
            setLoading(false);
        } else {
            document.getElementById(id).innerHTML = text.text;
        }

    }, [loading])

    const handleEdit = async () => {
        if(!showEdit) {
            setShowEdit(true);
        } else {
            await setShowEdit(false);
            document.getElementById(id).innerHTML = texte
            const orga = {
                titre,
                text : texte
            }
            handleChange(i, orga);
        }
    }

    const handleDelete = async () => {
        if(window.confirm('Attention la suppression est irréversible. Êtes-vous sûr ?')) {
            handleDeleteOrga(i);
        }
    }


    return (
        <>
        <div style={{backgroundColor : '#d8d7d7', padding : '1%', margin : '1%'}}>
            {!showEdit ? <><div className="st_recommandation">{titre}</div>
                {!loading &&
                <p id={id}></p>
                }</>
                : <><div className="st_recommandation">
                    <textarea defaultValue={titre} style={{width : '100%', height : '5vh'}} onChange={(e) => setTitre(e.target.value)}/>
                </div>
                    <textarea defaultValue={texte} style={{width : '100%', height : '10vh'}} onChange={(e) => setTexte(e.target.value)}/>
                    </>
            }
        </div>
            <button onClick={handleEdit} style={{marginLeft : '1%'}}>{showEdit ? <>Valider</> : <>Editer</>}</button>
            <button onClick={handleDelete} style={{marginLeft : '1%'}}>Supprimer</button>
        </>
    );
};

export default CardRecoAdmin;
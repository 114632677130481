import axios from "axios";

export function getRisquesByPT(id) {
    return axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/risques/by-pt/${id}`,
        withCredentials: true,
    });
}

// Fetch risques template from current user factory
export function getCurrentRisques() {
    return axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/risques/current`,
        withCredentials: true,
    });
}

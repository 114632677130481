import React from "react";
import "./PDFHeaderV.css";
import headerPDF from "../../assets/img/header.jpg";

function PDFHeaderV() {
    return (
        <div className="PDFHeaderPosition">
            <div className="imgHeader">
                <img alt="" style={{ width: "100vw" }} src={headerPDF} />
            </div>

            <div className="PDFHeader PDFHeaderV">
                <div
                    className="LeftHeader"
                    style={{ display: "flex", alignItems: "center" }}
                >
                    <p>Rédacteur du PT :</p>
                    <div className="redactor-field"></div>
                </div>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "20px",
                        marginTop: "20px",
                    }}
                >
                    <h2 style={{ marginTop: "10px" }}>PT N° : </h2>
                    <div className="PT-title-field"></div>
                </div>
            </div>
        </div>
    );
}
export default PDFHeaderV;

import React from "react";
import "./ComponentToPrint.css";
import PDFHeader from "../PDFHeader/PDFHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignature } from "@fortawesome/free-solid-svg-icons";
import { faFileSignature } from "@fortawesome/free-solid-svg-icons";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { buildPublicFileUrl, getValidatorStatus } from "../../../../Utils/utils";

// Using a class component, everything works without issue
export class ComponentToPrint extends React.PureComponent {
    render() {
        return (
            <div className="PDFValidateurContent">
                <PDFHeader
                    numPermis={this.props.pt.numero_PT}
                    prenom={this.props.eu.contact_RdP.prenomRdP}
                    nom={this.props.eu.contact_RdP.nomRdP}
                />

                <div className="ArrayValidateur">
                    <table className="PDFTable">
                        <thead className="">
                            <tr className="PDFTableTR">
                                <th className="PDFTableTH">EU/EE/ES</th>
                                <th className="PDFTableTH">Validateur</th>
                                <th className="PDFTableTH">Date</th>
                                <th className="PDFTableTH">Signature*</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.validateurs !== null &&
                                Object.entries(this.props.validateurs).map(([key, validateur]) => {
                                    if (validateur.signe === 2) {
                                        return (
                                            <tr className="PDFTableTR">
                                                <td className="PDFTableTD">{getValidatorStatus(key)}</td>
                                                <td className="PDFTableTD">
                                                    {validateur.prenom} {validateur.nom}
                                                </td>
                                                <td className="PDFTableTD">{new Date().toLocaleDateString("fr-FR")}</td>
                                                <td className="PDFTableTD"></td>
                                            </tr>
                                        );
                                    } else if (
                                        validateur.signe === 1 &&
                                        validateur.numerique === 1 &&
                                        validateur.signature !== undefined
                                    ) {
                                        return (
                                            <tr className="PDFTableTR">
                                                <td className="PDFTableTD">{getValidatorStatus(key)}</td>
                                                <td className="PDFTableTH">
                                                    {validateur.prenom} {validateur.nom}
                                                </td>
                                                <td className="PDFTableTD">
                                                    {validateur.date
                                                        ? new Date(validateur.date).toLocaleDateString("fr")
                                                        : new Date().toLocaleDateString("fr")}
                                                </td>
                                                <td className="PDFTableTD">
                                                    <img
                                                        src={buildPublicFileUrl(validateur.signature)}
                                                        alt="signature"
                                                        style={{ width: "100%" }}
                                                    />
                                                </td>
                                            </tr>
                                        );
                                    } else if (validateur.signe === 1 && validateur.numerique === 1) {
                                        return (
                                            <tr className="PDFTableTR">
                                                <td className="PDFTableTD">{getValidatorStatus(key)}</td>
                                                <td className="PDFTableTH">
                                                    {validateur.prenom} {validateur.nom}
                                                </td>
                                                <td className="PDFTableTD">{new Date().toLocaleDateString("fr-FR")}</td>
                                                <td className="PDFTableTD">
                                                    <FontAwesomeIcon icon={faSignature} />
                                                </td>
                                            </tr>
                                        );
                                    } else if (validateur.signe === 1 && validateur.numerique === 0) {
                                        return (
                                            <tr className="PDFTableTR">
                                                <td className="PDFTableTD">{getValidatorStatus(key)}</td>
                                                <td className="PDFTableTH">
                                                    {validateur.prenom} {validateur.nom}
                                                </td>
                                                <td className="PDFTableTD">{new Date().toLocaleDateString("fr-FR")}</td>
                                                <td className="PDFTableTD">
                                                    <FontAwesomeIcon icon={faFileSignature} />
                                                </td>
                                            </tr>
                                        );
                                    } else {
                                        return null;
                                    }
                                })}
                        </tbody>
                    </table>
                </div>
                <p style={{ textAlign: "center", fontSize: "12px" }}>
                    *Seules les lignes n'ayant pas de signatures doivent êtres signées et datées.
                    <br />
                    <FontAwesomeIcon icon={faSignature} /> : déjà signé numériquement.
                    <br />
                    <FontAwesomeIcon icon={faFileSignature} /> : déjà signé manuellement
                    <br />
                    <FontAwesomeIcon icon={faX} /> : ne signe pas maintenant
                </p>
            </div>
        );
    }
}

import React, { useState } from "react";

function ArriveeI(props) {
    const [modal, setModal] = useState(false);

    const toggleModalQRCode = () => { 
        setModal(!modal);
    };

    if(modal) {
        document.body.classList.add('active-modal')
        
    } else {
        document.body.classList.remove('active-modal')
    }

    
    const [hours, setHours] = useState(new Date().getHours());             
    const [minutes, setMinutes] = useState(new Date().getUTCMinutes());

    var hoursFormated = hours;
    if (hoursFormated < 10) {
        hoursFormated = "0" + hoursFormated;
    }
    var minutesFormated = minutes;
    if (minutesFormated < 10) {
        minutesFormated = "0" + minutesFormated;
    }             
    
    return(
        <div>
            <button onClick={toggleModalQRCode} className="boutonIntervenant">
                Arrivée
            </button>


            {modal && (
                <div className="AddValidateur_overlay">
                    <div className="AddValidateur_wrapper">
                        <div className="AddValidateur_modal">
                            <div className="AddValidateur_header">
                                Pointage d'arrivée
                                <button
                                    type="button"
                                    className="modal-close-button"
                                    onClick={toggleModalQRCode}
                                >
                                    X
                                </button>
                            </div>
                            <div>
                                <div className="infoValidateur">
                                    <p style={{paddingBottom: '20px'}}>Prénom</p>
                                    <p style={{paddingBottom: '20px'}}>Nom</p>
                                    <p style={{paddingBottom: '30px'}}>Date</p>
                                </div>
                                

                                <div style={{paddingBottom: '60px'}}>
                                    
                                    <h4>HORAIRE</h4>
                                    <div className="d-flex align-items-center">
                                        <input type="text" className="inputDepart" disabled style={{marginBottom: '10px'}} value={hoursFormated} />
                                        <label style={{marginLeft: '20px'}}>Heure</label>
                                    </div>

                                    <div  className="d-flex align-items-center">
                                        <input className="inputDepart" disabled value={minutesFormated} />
                                        <label style={{marginLeft: '20px'}}>Minutes</label>
                                    </div>
                                </div>
                                

                                <button
                                    type="button"
                                    className="modal_button_imprimer_validateur"
                                >
                                    Valider
                                </button>
                            </div>
                        </div>
                    </div>
                    
                    
                </div>
            )}
        </div>
    )
}

export default ArriveeI;
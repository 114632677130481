import React from "react";
import PDFHeader from "../../PDFHeader/PDFHeader";

// Using a class component, everything works without issue
export class BoutonARPDF extends React.Component {
    state = {
        mesures: [],
        renderRow: false,
        loading: true,
    };

    componentDidMount() {
        // console.log(this.props.titre);
        if (this.state.loading) {
            // Keep only the mesures that are checked with EE or EU
            const mesures = Object.values(JSON.parse(this.props.risque.mesures_prevention)).filter(
                (mesure) => mesure.eu === "1" || mesure.ee === "1"
            );
            if (mesures.length > 0) {
                console.log("RISQUE", mesures);
            }

            // Render the row only if there is at least one mesure with EE or EU
            const renderRow = mesures.length > 0;

            this.setState({ renderRow, mesures: mesures, loading: false });
        }
    }

    render() {
        let componentToRender = null;
        if (this.state.renderRow) {
            componentToRender = this.state.mesures.map((mesure, index) => {
                if (index === 0) {
                    return (
                        <tr key={mesure.text}>
                            <th scope="row" rowSpan={this.state.mesures.length}>
                                {this.props.risque.titre_risque}
                                <br />
                                <span style={{ fontSize: "10px" }}>{this.props.risque.description_risque}</span>
                            </th>
                            <RisqueMesuresValues mesure={mesure} key={mesure.text} />
                        </tr>
                    );
                }
                return (
                    <>
                        <tr key={mesure.text}>
                            <RisqueMesuresValues mesure={mesure} key={mesure.text} />
                        </tr>
                    </>
                );
            });
        }
        return <>{componentToRender}</>;
    }
}

export class RisqueMesuresValues extends React.PureComponent {
    render() {
        if (this.props.mesure === undefined) return null;
        return (
            <>
                <td>{this.props.mesure.text}</td>
                <td>{this.props.mesure.eu === "1" ? "X" : "O"}</td>
                <td>{this.props.mesure.ee === "1" ? "X" : "O"}</td>
            </>
        );
    }
}

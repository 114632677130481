import React from "react";
import "./Sommaire.css";
import PDFHeaderV from "../../PDFHeaderV/PDFHeader";

// Using a class component, everything works without issue
export class Sommaire extends React.PureComponent {
    render() {
        return (
            <div className="PDFValidateurContent">
                <PDFHeaderV />
                <div className="sommairePDF">
                    <h1>PLAN DE TRAVAIL Vierge</h1>
                    <h2>SOMMAIRE</h2>
                    <div className="d-flex align-items-center justify-content-evenly">
                        <div>
                            <h3>I - DESCRIPTION</h3>
                            <h3>II - RECOMMANDATIONS</h3>
                            <h3>III - ANALYSE DES RISQUES</h3>
                            <h3>IV - VALIDATION</h3>
                            <h3>V - INTERVENANTS</h3>
                            <h3>VI - FIN DE CHANTIER</h3>
                            {/* <h3>VI - DOCUMENTS</h3> */}
                        </div>
                    </div>
                </div>

                <div className="pagebreak"></div>
            </div>
        );
    }
}

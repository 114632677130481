import React, { useEffect, useMemo, useState } from "react";
import "./BoutonAR.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import CardCheckBox from "./CardCheckBox";
import { setRecoOrgaSec } from "../../../../Redux/actions/recommandation.actions";
import { deleteAR } from "../../../../Redux/actions/risqueOriginaux.actions";
import { useDispatch } from "react-redux";

function BoutonAR({
    titreAR,
    descriptionAR,
    mesure_prevention,
    handleSetData,
    handleEditDescription,
    handleEditTitre,
    handleAddNewCond,
    idUsine,
    id,
}) {
    const [show, setShow] = useState(false);
    const [modal, setModal] = useState(false);

    const [desc, setDesc] = useState(descriptionAR);
    const [titre, setTitre] = useState(titreAR);
    const [newTexte, setNewTexte] = useState("");

    const [showEditDesc, setShowEditDesc] = useState(false);
    const [showEditTitre, setShowEditTitre] = useState(false);
    const [showNewCond, setShowNewCond] = useState(false);

    const dispatch = useDispatch();

    const toggleModalAR = () => {
        setModal(!modal);
        setShow(!show);
    };

    const handleEditText = (mesure, text) => {
        const newDatas = mesure_prevention;
        Object.keys(newDatas).map((key) => {
            if (newDatas[key].text === mesure.text) {
                newDatas[key].text = text;
            }
        });
        handleSetData(newDatas, titreAR);
    };

    const handleEditDesc = () => {
        if (!showEditDesc) {
            setShowEditDesc(!showEditDesc);
        } else {
            handleEditDescription(titreAR, desc);
            setShowEditDesc(!showEditDesc);
        }
    };

    const handleEditTit = () => {
        if (!showEditTitre) {
            setShowEditTitre(!showEditTitre);
        } else {
            handleEditTitre(titreAR, titre);
            setShowEditTitre(!showEditTitre);
        }
    };

    const handleDeleteText = (text) => {
        const newDatas = mesure_prevention;
        Object.keys(newDatas).map((key) => {
            if (newDatas[key].text === text) {
                delete newDatas[key];
            }
        });
        handleSetData(newDatas, titreAR);
    };

    const handleNewCond = async () => {
        if (!showNewCond) {
            setShowNewCond(!showNewCond);
        } else {
            let data = mesure_prevention;
            const length = Object.keys(data).length;
            console.log(length);
            let lastKey = "";
            Object.keys(data).map((key, i) => {
                if (i === length - 1) {
                    lastKey = key;
                }
            });
            let newKey = lastKey.replace("obj", "");
            newKey = parseInt(newKey);
            newKey = newKey + 1;
            newKey = "obj" + newKey;
            data[newKey] = {
                text: newTexte,
                eu: "0",
                ee: "0",
            };
            handleAddNewCond(titreAR, data);
            setShowNewCond(!showNewCond);
        }
    };

    const handleDeleteAR = async () => {
        if (window.confirm("Attention la suppression est irréversible. Êtes-vous sûr ?")) {
            await dispatch(deleteAR(idUsine, titreAR, id));
        }
    };

    return (
        <div>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                }}
            >
                {!showEditTitre ? (
                    <button onClick={toggleModalAR} className="ARButton">
                        {titreAR}{" "}
                        {!modal ? (
                            <>
                                <FontAwesomeIcon icon={faArrowDown} />
                            </>
                        ) : (
                            <>
                                <FontAwesomeIcon icon={faArrowRight} />
                            </>
                        )}
                    </button>
                ) : (
                    <textarea defaultValue={titreAR} onChange={(e) => setTitre(e.target.value)}></textarea>
                )}

                <button style={{ height: "fit-content", marginLeft: "1%" }} onClick={handleEditTit}>
                    {showEditTitre ? "Valider le titre" : "Editer le titre"}
                </button>
                <button style={{ height: "fit-content", marginLeft: "1%" }} onClick={handleDeleteAR}>
                    Supprimer l'AR
                </button>
            </div>

            {show ? (
                <div style={{ width: "500px", maxWidth: "100%" }}>
                    {!showEditDesc ? (
                        <p style={{ fontWeight: "bold", marginLeft: "2%" }}>{descriptionAR}</p>
                    ) : (
                        <>
                            <textarea defaultValue={descriptionAR} onChange={(e) => setDesc(e.target.value)}></textarea>
                            <br />
                        </>
                    )}
                    <button onClick={handleEditDesc} style={{ marginRight: "1%" }}>
                        {showEditDesc ? "Valider la description" : "Editer la description"}
                    </button>
                    {mesure_prevention !== null &&
                        mesure_prevention !== undefined &&
                        mesure_prevention !== "" &&
                        Object.keys(mesure_prevention).map((obj) => {
                            return (
                                <CardCheckBox
                                    object={mesure_prevention[obj]}
                                    handleEditText={handleEditText}
                                    handleDelete={handleDeleteText}
                                    idUsine={idUsine}
                                />
                            );
                        })}
                    {showNewCond && (
                        <div
                            style={{
                                marginTop: "2%",
                                backgroundColor: "#e1e1e1",
                                borderStyle: "solid",
                                borderColor: "black",
                                borderWidth: "1px",
                                padding: "2%",
                            }}
                        >
                            <textarea
                                className="form-check-label"
                                style={{ width: "350px", paddingLeft: "20px" }}
                                onChange={(e) => setNewTexte(e.target.value)}
                            ></textarea>
                        </div>
                    )}
                    <button style={{ marginTop: "1%" }} onClick={handleNewCond}>
                        {showNewCond ? "Valider l'ajout de mesure de prévention" : "Ajouter une mesure de prévention"}
                    </button>
                    {showNewCond && (
                        <button style={{ marginLeft: "1%" }} onClick={() => setShowNewCond(!showNewCond)}>
                            Annuler
                        </button>
                    )}
                </div>
            ) : null}
        </div>
    );
}

export default BoutonAR;

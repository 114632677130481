import React, { Component } from "react";
import Bandeau from "./Bandeau/Bandeau";
import Menu from "./Menu/Menu";
import KPI from "./KPI/KPI";

class Accueil extends Component {
    render() {
        return(
            <div>
                <Bandeau />
                <Menu />
                <KPI />
            </div>
        );
    }
}

export default Accueil;
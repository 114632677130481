import axios from "axios";

export const GET_PT = "GET_PT";

export const getPt = (id_usine) => {
    return (dispatch) => {
        return axios({
            method: "put",
            url: `${process.env.REACT_APP_API_URL}/pt`,
            data: { id: id_usine },
            withCredentials: true,
        })
            .then((res) => {
                dispatch({ type: GET_PT, payload: res.data });
            })
            .catch((err) => {
                console.log(err);
            });
    };
};

export const getInvolvedPt = (id_usine) => {
    return (dispatch) => {
        return axios({
            method: "put",
            url: `${process.env.REACT_APP_API_URL}/pt/involved`,
            data: { id: id_usine },
            withCredentials: true,
        })
            .then((res) => {
                dispatch({ type: GET_PT, payload: res.data });
            })
            .catch((err) => {
                console.log(err);
            });
    };
};

export const updateDescPT = (id, data) => {
    return (dispatch) => {
        return axios({
            method: "put",
            url: `${process.env.REACT_APP_API_URL}/pt/updateDesc/${id.toString()}`,
            data: data,
            withCredentials: true,
        })
            .then((res) => {
                alert("Modifications sauvegardées");
                window.location = `/pt/description/${id.toString()}`;
            })
            .catch((err) => {
                console.log(err);
                alert("Erreur dans la sauvegarde");
            });
    };
};

export const createPT = (data) => {
    return (dispatch) => {
        console.log("ADD 1 Begin");
        axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}/pt/createDesc`,
            data: data,
            withCredentials: true,
        })
            .then((res) => {
                alert("PT créé avec succès");
                window.location = `/pt/description/${res.data}`;
            })
            .catch((err) => {
                alert("Erreur dans la création");
            });
    };
};

export const updateOrgaPT = (id, data) => {
    return (dispatch) => {
        return axios({
            method: "put",
            url: `${process.env.REACT_APP_API_URL}/pt/updateOrga/${id}`,
            data: data,
            withCredentials: true,
        })
            .then((res) => {
                alert("Recommendations sauvegardées");
            })
            .catch((err) => {
                console.log(err);
                alert("Erreur dans la sauvegarde");
            });
    };
};

// export const generateReco = (num, id_usine) => {
//     return (dispatch) => {
//         axios({
//             method: "put",
//             url: `${process.env.REACT_APP_API_URL}/pt/updateReco/${num}`,
//             data: { id: id_usine },
//             withCredentials: true,
//         })
//             .then(() => {
//                 console.log("done");
//             })
//             .catch((err) => {
//                 console.log("err");
//                 alert("Erreur dans la création");
//             });
//     };
// };

export const updateValidateurs = (id, data) => {
    return (dispatch) => {
        return axios({
            method: "put",
            url: `${process.env.REACT_APP_API_URL}/updateValidation/${id}`,
            data: data,
            withCredentials: true,
        })
            .then((res) => {
                console.log("done");
            })
            .catch((err) => {
                console.log(err);
            });
    };
};

export const updateSignataireFin = (id, data) => {
    return (dispatch) => {
        return axios({
            method: "put",
            url: `${process.env.REACT_APP_API_URL}/updateSignataires/${id}`,
            data: data,
            withCredentials: true,
        })
            .then((res) => {
                console.log("done");
            })
            .catch((err) => {
                console.log(err);
            });
    };
};

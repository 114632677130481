import {GET_ENTREPRISE} from "../actions/entreprise.actions";

const initialState = {};

export default function contactsReducer( state = initialState, action) {

    switch (action.type) {
        case GET_ENTREPRISE:
            return action.payload;

        default:
            return state;
    }
}
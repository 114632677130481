import React, { useEffect, useState } from "react";
import "./Intervenants.css";
import Navbar from "../../NavBar/Navbar";
import NavbarNewPT from "../NavbarNewPT/NavbarNewPT";
import HeaderNewPT from "../HeaderNewPT/HeaderNewPT";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import ImprimerIntervenants from "../HeaderNewPT/BoutonsHeader/Validateur/Imprimer/ImprimerIntervenants";
import QRCode from "../HeaderNewPT/BoutonsHeader/QRCode/QRCode";
import AddValidateur from "../HeaderNewPT/BoutonsHeader/Validateur/AddValidateur/AddValidateur";
import AddIntervenant from "../HeaderNewPT/BoutonsHeader/Validateur/AddValidateur/AddIntervenant";
import PlanningInterEE from "./PlanningIntervenants/PlanningInterEE";
import ModifierDate from "./Boutons/ModifierDate/ModifierDate";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getIntervenant } from "../../../../Redux/actions/intervenant.actions";
import { getContacts } from "../../../../Redux/actions/contacts.actions";
import { getPt } from "../../../../Redux/actions/pt.actions";
import { buildPublicFileUrl, isEmpty } from "../../../../Utils/utils";
import axios from "axios";
import { getEntreprise } from "../../../../Redux/actions/entreprise.actions";
import PDFIntervenantsDocusign from "../../PDF/PDFIntervenants/PDFIntervenantsDocusign";
import NewPTContent from "../NewPTContent";
import { getIntervenantsRequest } from "../../../../Utils/api/users.api";
import SignatureModal from "../../SignatureModal/SignatureModal";
import { addSignatureIntervenant, getDailyValidations } from "../../../../Utils/api/signature.api";

const Intervenants = () => {
    const { id } = useParams();

    const [date, setDate] = useState(
        new Date().toLocaleString("fr-FR", { weekday: "short", day: "2-digit", month: "long", year: "numeric" })
    );

    const [jsonDate, setJsonDate] = useState(new Date().toJSON().split("T")[0]);
    const [showUpdateDate, setShowUpdateDate] = useState(false);

    const setShowDate = () => {
        setShowUpdateDate(!showUpdateDate);
    };

    const handleChangeDate = (date) => {
        setDate(date.toLocaleString("default", { weekday: "short", day: "2-digit", month: "long", year: "numeric" }));

        const jDate = new Date(date);
        jDate.setDate(jDate.getDate() + 1);
        setJsonDate(jDate.toJSON().split("T")[0]);
    };

    const updateIntervenants = async () => {
        const newIntervenants = [];
        const requestedIntervenants = await getIntervenantsRequest();

        if (!isEmpty(requestedIntervenants.data[0])) {
            requestedIntervenants.data.map((intervenant) => {
                if (intervenant.id_PT.toString() === id) {
                    newIntervenants.push(intervenant);
                }
            });
        }
        setLesIntervenants(newIntervenants);
    };

    const handleUpdatePointage = async (data, intervenant) => {
        const pointages = JSON.parse(intervenant.pointage_jour);
        console.log(data);
        let keyOfDay = "";
        if (pointages !== null) {
            for (const [key, value] of Object.entries(pointages)) {
                if (value.jour === data.jour) {
                    keyOfDay = key;
                }
            }
        }
        if (keyOfDay !== "") {
            pointages[keyOfDay] = data;
            await axios({
                method: "put",
                url: `${process.env.REACT_APP_API_URL}/pt/updatePointageIntervenant/${id}/${intervenant.id_contact}`,
                data: pointages,
                withCredentials: true,
            })
                .then(async (res) => {
                    if (res) {
                        alert("Pointage mis à jour");
                        await updateIntervenants();
                        window.location = window.location;
                    }
                })
                .catch((err) => {
                    console.log(err);
                    alert("Erreur dans la sauvegarde du pointage");
                });
        } else {
            let name = "pointage1";
            if (pointages !== null) {
                const length = Object.keys(pointages).length + 1;
                name = "pointage" + length;
                pointages[name] = data;

                await axios({
                    method: "put",
                    url: `${process.env.REACT_APP_API_URL}/pt/updatePointageIntervenant/${id}/${intervenant.id_contact}`,
                    data: pointages,
                    withCredentials: true,
                })
                    .then(async (res) => {
                        if (res) {
                            alert("Pointage mis à jour");
                            await updateIntervenants();
                            window.location = window.location;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        alert("Erreur dans la sauvegarde du pointage");
                    });
            } else {
                let object = {};
                object[name] = data;

                await axios({
                    method: "put",
                    url: `${process.env.REACT_APP_API_URL}/pt/updatePointageIntervenant/${id}/${intervenant.id_contact}`,
                    data: object,
                    withCredentials: true,
                })
                    .then(async (res) => {
                        if (res) {
                            alert("Pointage mis à jour");
                            await updateIntervenants();
                            window.location = window.location;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        alert("Erreur dans la sauvegarde du pointage");
                    });
            }
        }
    };

    const [loading, setLoading] = useState(true);
    const [done, setDone] = useState(false);
    const [wait, setWait] = useState(true);

    const [pt, setPt] = useState({});
    const [ee, setEE] = useState({});
    const [eeST, setEEST] = useState({});
    const [eu, setEU] = useState({});
    const [lesIntervenants, setLesIntervenants] = useState([]);
    const [dailyValidations, setDailyValidations] = useState({});

    const [handleDocusign, setHandleDocusign] = useState(false);

    const [showSign, setShowSign] = useState(false);

    const intervenantReducer = useSelector((state) => state.intervenantReducer);
    const contactsReducer = useSelector((state) => state.contactsReducer);
    const ptReducer = useSelector((state) => [state.ptReducer]);
    const userReducer = useSelector((state) => state.userReducer);

    const dispatch = useDispatch();

    const fetchData = async () => {
        if (userReducer.id !== undefined) {
            await dispatch(getIntervenant());
            await dispatch(getContacts());
            await dispatch(getEntreprise());
            await dispatch(getPt(userReducer.id_hash_usine));
            setLoading(false);
        }
    };

    useEffect(() => {
        if (loading) {
            fetchData();
        } else {
            if (!done) {
                if (!isEmpty(ptReducer[0])) {
                    ptReducer[0].map((pts) => {
                        if (pts.id.toString() === id) {
                            setPt(pts);
                        }
                    });
                }

                let intervenants = [];
                if (!isEmpty(intervenantReducer[0])) {
                    intervenantReducer.map((intervenant) => {
                        if (intervenant.id_PT.toString() === id) {
                            intervenants.push(intervenant);
                        }
                    });
                }
                setLesIntervenants(intervenants);
                setDone(true);
            } else {
                if (wait) {
                    try {
                        setEE(JSON.parse(pt.entreprise_exterieure_PT));
                        setEEST(JSON.parse(pt.entreprise_ST_PT));
                        setEU(JSON.parse(pt.entreprise_utilisatrice_PT));
                        setWait(false);
                    } catch (error) {
                        console.log("Error setting intervenants");
                    }
                }
            }
        }
    }, [loading, done, wait, ptReducer, userReducer, contactsReducer, intervenantReducer, date]);

    useEffect(() => {
        let settled = false;
        if (!isEmpty(pt)) {
            if (!isEmpty(pt.dailyValidations)) {
                setDailyValidations(JSON.parse(pt.dailyValidations));
                settled = true;
            }
        }
        if (!settled) {
            getDailyValidations(id)
                .then((res) => {
                    setDailyValidations(res);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, []);

    const handleSign = async (signatureFile) => {
        try {
            const newDailyValidations = await addSignatureIntervenant(
                pt.id,
                signatureFile,
                {
                    prenom: eu?.contact_RdP?.prenomRdP,
                    nom: eu?.contact_RdP?.nomRdP,
                },
                jsonDate,
                pt.id_usine,
                pt.numero_PT
            );
            setShowSign(false);
            setDailyValidations(newDailyValidations);
            // onSign(newValidators);
        } catch (error) {
            console.log("Error on sign end request", error);
        }
    };

    return (
        <div>
            <Navbar />
            <NavbarNewPT id={id} />
            <HeaderNewPT titre="Intervenants" />
            {eu && (
                <ImprimerIntervenants
                    date={date}
                    eu={eu}
                    pt={pt}
                    intervenants={lesIntervenants}
                    contacts={contactsReducer}
                    signature={
                        dailyValidations && dailyValidations[jsonDate]
                            ? dailyValidations[jsonDate].signature
                            : undefined
                    }
                />
            )}
            <AddIntervenant eu={eu} ee={ee} es={eeST} pt={pt} />
            <QRCode pt={pt} />

            <NewPTContent style={{ paddingLeft: 0, paddingRight: 0 }}>
                <div className="bandeauDateI">
                    <div className="dateJourI">{date}</div>
                    <div style={{ zIndex: 99}}>
                        <ModifierDate handleChangeDate={handleChangeDate} showUpdateDate={setShowDate} />
                    </div>
                </div>
                {(!dailyValidations || dailyValidations[jsonDate] === undefined) && (
                    <div className="bandeauValidationI d-flex justify-content-between align-items-center">
                        <div>
                            {!wait && (
                                <>
                                    <h5>VALIDATION QUOTIDIENNE</h5>
                                    <span>
                                        {eu?.contact_RdP?.prenomRdP} {eu?.contact_RdP?.nomRdP}
                                    </span>
                                </>
                            )}
                        </div>
                        <div>
                            <button
                                className="buttonValidationI btn btn-success"
                                style={{ display: "flex", alignItems: "center", gap: "10px" }}
                                onClick={() => setShowSign(true)}
                            >
                                <FontAwesomeIcon icon={faCheckSquare} /> Valider
                            </button>
                        </div>
                    </div>
                )}
                {dailyValidations && dailyValidations[jsonDate] && (
                    <div className="box_vspt">
                        <div className="box_vnspt_header">
                            {eu?.contact_RdP?.prenomRdP} {eu?.contact_RdP?.nomRdP}
                        </div>
                        <div className="box_vspt_button" style={{ marginBottom: "20px" }}>
                            <FontAwesomeIcon className="check" icon={faCheck} />
                            Signé
                        </div>
                        {dailyValidations[jsonDate].signature && (
                            <img
                                src={buildPublicFileUrl(dailyValidations[jsonDate].signature)}
                                alt="signature"
                                style={{ width: "200px" }}
                            />
                        )}
                    </div>
                )}
                <SignatureModal
                    isOpen={showSign}
                    onClose={() => {
                        setShowSign(false);
                    }}
                    title="Signature de validation"
                    validator={{
                        prenom: eu?.contact_RdP?.prenomRdP,
                        nom: eu?.contact_RdP?.nomRdP,
                    }}
                    onValidate={handleSign}
                />
                {/* {handleDocusign && (
                    <div>
                        <div style={{ margin: "30px", textAlign: "center" }}>
                            En cliquant sur envoyer, un pdf vous sera proposé, il faut le télécharger puis le
                            transmettre dans le formulaire suivant.
                        </div>
                        <PDFIntervenantsDocusign
                            date={date}
                            eu={eu}
                            pt={pt}
                            intervenants={lesIntervenants}
                            contacts={contactsReducer}
                        />
                    </div>
                )} */}

                <div className="intervenants-table">
                    <table
                        className="table intervenants-table-header"
                        style={{ marginTop: "50px", borderBottom: "1px solid #f5f5f5", color: "grey", width: "100%"}}
                    >
                        <thead>
                            <tr style={{ position: "fixed", display: "flex", zIndex: -5, paddingRight: "2%" }}>
                                <td style={{ textAlign: "center", width: "20%" }}>NOM</td>
                                <td style={{ textAlign: "center", width: "20%" }}>ARRIVÉE</td>
                                <td style={{ textAlign: "center", width: "20%" }}>DÉPART</td>
                                <td style={{ textAlign: "center", width: "20%" }}>PAUSE</td>
                                <td style={{ textAlign: "center", width: "20%" }}>ACTIONS/TOTAL</td>
                            </tr>
                        </thead>
                    </table>
                    {showUpdateDate ? (
                        <div className="tableau_intervenant"
                            style={{
                                marginTop: "25px",
                                height: "50%",
                                overflowY: "auto",
                                position: "fixed",
                                
                                paddingRight: "2%",
                                zIndex: -1,
                            }}
                        >
                            <div className="titre_vnspt" style={{ marginBottom: "15px" }}>
                                {!wait && ee.nom_EE_PT}
                            </div>

                            {!wait &&
                                lesIntervenants.map((inter) => {
                                    if (inter.entreprise_pt === "ee") {
                                        return (
                                            <PlanningInterEE
                                                intervenant={inter}
                                                date={date}
                                                handleUpdatePointage={handleUpdatePointage}
                                                pt={pt}
                                                id_EE={ee.nom_EE_PT}
                                            />
                                        );
                                    }
                                })}

                            <div className="titre_vnspt" style={{ marginBottom: "15px" }}>
                                {!wait && eeST.nom_ST_PT}
                            </div>

                            {!wait &&
                                lesIntervenants.map((inter) => {
                                    if (inter.entreprise_pt === "es") {
                                        return (
                                            <PlanningInterEE
                                                intervenant={inter}
                                                date={date}
                                                handleUpdatePointage={handleUpdatePointage}
                                                pt={pt}
                                                id_EE={eeST.nom_ST_PT}
                                            />
                                        );
                                    }
                                })}
                        </div>
                    ) : (
                        <div className="tableau_intervenant"
                            style={{
                                marginTop: "25px",
                                height: "50%",
                                overflowY: "auto",
                                position: "fixed",
                                
                                paddingRight: "2%",
                                zIndex: 99,
                            }}
                        >
                            <div className="titre_vnspt" style={{ marginBottom: "15px" }}>
                                {!wait && ee.nom_EE_PT}
                            </div>

                            {!wait &&
                                lesIntervenants.map((inter) => {
                                    if (inter.entreprise_pt === "ee") {
                                        return (
                                            <PlanningInterEE
                                                intervenant={inter}
                                                date={date}
                                                handleUpdatePointage={handleUpdatePointage}
                                                pt={pt}
                                                id_EE={ee.nom_EE_PT}
                                            />
                                        );
                                    }
                                })}

                            <div className="titre_vnspt" style={{ marginBottom: "15px" }}>
                                {!wait && eeST.nom_ST_PT}
                            </div>

                            {!wait &&
                                lesIntervenants.map((inter) => {
                                    if (inter.entreprise_pt === "es") {
                                        return (
                                            <PlanningInterEE
                                                intervenant={inter}
                                                date={date}
                                                handleUpdatePointage={handleUpdatePointage}
                                                pt={pt}
                                                id_EE={eeST.nom_ST_PT}
                                            />
                                        );
                                    }
                                })}
                        </div>
                    )}
                </div>
            </NewPTContent>
        </div>
    );
};

export default Intervenants;

import React, { useState } from "react";
import { QRCodeSVG } from "qrcode.react";
import { hostUrl } from "../../../../../../Utils/constant/url";

function QRCode({ pt }) {
    const [modal, setModal] = useState(false);

    const toggleModalQRCode = () => {
        setModal(!modal);
    };

    if (modal) {
        document.body.classList.add("active-modal");
    } else {
        document.body.classList.remove("active-modal");
    }

    return (
        <div>
            <button onClick={toggleModalQRCode} className="button_add_qrcode" style={{ zIndex: 0 }}>
                Afficher le QRCode
            </button>

            {modal && (
                <div className="AddValidateur_overlay">
                    <div className="AddValidateur_wrapper">
                        <div className="AddValidateur_modal">
                            <div className="AddValidateur_header">
                                QRCode
                                <button type="button" className="modal-close-button" onClick={toggleModalQRCode}>
                                    X
                                </button>
                            </div>

                            <div className="AddValidateur_content" style={{ height: "100%", margin: "50%" }}>
                                <QRCodeSVG value={`${hostUrl}/login-ee/` + pt.id} size={250} />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default QRCode;

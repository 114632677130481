import axios from "axios";

export const GET_RISQUES = "GET_RISQUES";

export const getRisques = () => {
    return (dispatch) => {
        return axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}/risques`,
            withCredentials: true,
        })
            .then((res) => {
                dispatch({ type: GET_RISQUES, payload: res.data });
            })
            .catch((err) => console.log(err));
    };
};

// export const getPTRisques = (id) => {
//     return (dispatch) => {
//         return axios({
//             method: "get",
//             url: `${process.env.REACT_APP_API_URL}/risques/by-pt/${id}`,
//             withCredentials: true
//         })
//             .then((res) => {
//                 dispatch({type: GET_RISQUES, payload : res.data})
//             })
//             .catch((err) => console.log(err));
//     }
// }

export const updateAnalyseRisque = (id, data) => {
    return (dispatch) => {
        return axios({
            method: "put",
            url: `${process.env.REACT_APP_API_URL}/updateRisque/${id}`,
            data: data,
            withCredentials: true,
        })
            .then((res) => {
                //do nothing
            })
            .catch((err) => {
                console.log(err);
            });
    };
};

export const updateModeOpeCom = (id, data) => {
    return (dispatch) => {
        return axios({
            method: "put",
            url: `${process.env.REACT_APP_API_URL}/updateModeOpeCom/${id}`,
            data: data,
            withCredentials: true,
        })
            .then((res) => {
                //do nothing
            })
            .catch((err) => {
                console.log(err);
            });
    };
};

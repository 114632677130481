import React, { useEffect, useState } from "react";
import "./Utilisateurs.css";
import NavbarAdmin from "../NavbarAdmin/NavbarAdmin";
import HeaderAdmin from "../HeaderAdmin/HeaderAdmin";
import MenuContextuelAdmin from "./MenuContextuelAdmin/MenuContextuelAdmin";
import AddUser from "../HeaderAdmin/AddUser/AddUser";
import { useDispatch, useSelector } from "react-redux";
import { getUsines } from "../../../Redux/actions/usine.actions";
import { getUsers } from "../../../Redux/actions/users.actions";
import CardUser from "./CardUser";
import UpdateUser from "../HeaderAdmin/AddUser/UpdateUser";

const Utilisateurs = () => {
    const usineReducer = useSelector((state) => state.usineReducer);
    const usersReducer = useSelector((state) => state.usersReducer);
    const userReducer = useSelector((state) => state.userReducer);
    const [loading, setLoading] = useState(true);
    const [showUpdate, setShowUpdate] = useState(false);
    const [userToUpdate, setUserToUpdate] = useState({});

    const [showInactif, setShowInactif] = useState(false);

    const dispatch = useDispatch();

    const fetchData = async () => {
        await dispatch(getUsines());
        await dispatch(getUsers());
        setLoading(false);
    };

    const handleShowUserUpdate = (user) => {
        setUserToUpdate(user);
        setShowUpdate(!showUpdate);
    };

    const handleCloseUpdate = () => {
        setShowUpdate(!showUpdate);
    };

    useEffect(() => {
        if (userReducer.id !== undefined) {
            if (userReducer.admin !== 1) {
                window.location = "/accueil";
            } else {
                if (loading) {
                    fetchData();
                }
            }
        }
    }, [usineReducer, userReducer]);

    return (
        !loading && (
            <div>
                <NavbarAdmin />
                <div style={{ position: "fixed", top: "0px", left: "25%" }}>
                    <HeaderAdmin titre="Utilisateurs" />
                </div>

                <AddUser className="desktop-only" currentUser={userReducer} />
                {showUpdate && <UpdateUser user={userToUpdate} handleCloseUpdate={handleCloseUpdate} />}

                <div className="content_Utilisateurs aside-menu-content under-header-content">
                    <AddUser className="mobile-only" currentUser={userReducer} />

                    <div
                        style={{
                            width: "100%",
                            backgroundColor: "white",
                            paddingLeft: "12px",
                        }}
                        className="user-settings-show-profiles"
                    >
                        <input type="checkbox" onChange={(e) => setShowInactif(e.target.checked)} />
                        <label style={{ marginLeft: "20px", marginBottom: "20px", color: "black" }}>
                            Afficher les profils inactifs
                        </label>
                    </div>
                    <table className="table table-borderless" style={{ color: "grey", border: "none" }}>
                        <thead>
                            <tr className="tableau_header user-settings-table-header">
                                <th style={{ width: "24%" }}>PRÉNOM/NOM</th>
                                <td style={{ width: "24%" }} id="numeroPT">
                                    NOM DE L'USINE
                                </td>
                                <td style={{ width: "24%" }} id="titrePT">
                                    STATUT
                                </td>
                                <td style={{ width: "24%" }} id="titrePT">
                                    HABILITATIONS
                                </td>
                            </tr>
                        </thead>

                        <tbody style={{ borderTop: "none" }}>
                            <div style={{ marginTop: "160px", zIndex: -6 }}>
                                {usersReducer.map((user) => {
                                    if (showInactif) {
                                        if (user.id_hash_usine === userReducer.id_hash_usine) {
                                            return <CardUser user={user} handleShowUserUpdate={handleShowUserUpdate} />;
                                        }
                                    } else {
                                        if (user.active === 1) {
                                            if (user.id_hash_usine === userReducer.id_hash_usine) {
                                                return (
                                                    <CardUser user={user} handleShowUserUpdate={handleShowUserUpdate} />
                                                );
                                            }
                                        }
                                    }
                                })}
                            </div>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    );
};

export default Utilisateurs;

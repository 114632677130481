import React, { forwardRef, useImperativeHandle, useState } from "react";
import "./Filtre.css";

const Filtre = forwardRef(
    (
        {
            handleChangeNumFiltre,
            handleChangeDateDebFiltre,
            handleChangeDateFinFiltre,
            handleChangeEEFiltre,
            handleChangeRdpFiltre,
            handleChangeTitreFiltre,
        },
        ref
    ) => {
        const [show, setShow] = useState(true);

        const handleChangeNum = (value) => {
            handleChangeNumFiltre(value);
        };

        const handleChangeTitre = (value) => {
            handleChangeTitreFiltre(value);
        };

        const handleChangeEE = (value) => {
            handleChangeEEFiltre(value);
        };

        const handleChangeDateDeb = (value) => {
            handleChangeDateDebFiltre(value);
        };

        const handleChangeDateFin = (value) => {
            handleChangeDateFinFiltre(value);
        };

        const handleChangeRdp = (value) => {
            handleChangeRdpFiltre(value);
        };

        useImperativeHandle(ref, () => ({
            handleReset() {
                if (show) {
                    setShow(!show);
                    handleChangeNum("");
                    handleChangeTitre("");
                    handleChangeEE("");
                    handleChangeDateDeb("");
                    handleChangeDateFin("");
                    handleChangeRdp("");
                } else {
                    setShow(!show);
                }
            },
        }));

        return (
            <div className="filtre">
                {show ? (
                    <div className="bandeau_filtre aside-menu-content">
                        <input
                            id="filtreNumPT"
                            type="text"
                            placeholder="N° PT"
                            onChange={(e) => handleChangeNum(e.target.value)}
                        />

                        <input
                            type="text"
                            name="title-filter"
                            id="title-filter"
                            placeholder="Titre du PT"
                            onChange={(e) => handleChangeTitre(e.target.value)}
                        />

                        <input
                            type="text"
                            name="ee-filter"
                            id="ee-filter"
                            placeholder="Nom de l'EE"
                            onChange={(e) => handleChangeEE(e.target.value)}
                        />

                        <input
                            type="text"
                            name="begin-date"
                            id="begin-date"
                            placeholder="Date de début"
                            onChange={(e) => handleChangeDateDeb(e.target.value)}
                        />

                        <input
                            type="text"
                            name="end-date"
                            id="end-date"
                            placeholder="Date de fin"
                            onChange={(e) => handleChangeDateFin(e.target.value)}
                        />

                        <input
                            type="text"
                            name="redactor"
                            id="redactor"
                            placeholder="Rédacteur du permis"
                            onChange={(e) => handleChangeRdp(e.target.value)}
                        />
                    </div>
                ) : null}
            </div>
        );
    }
);

export default Filtre;

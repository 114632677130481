import React, { useEffect, useState } from "react";
import "./Validation.css";
import Navbar from "../../NavBar/Navbar";
import NavbarNewPT from "../NavbarNewPT/NavbarNewPT";
import HeaderNewPT from "../HeaderNewPT/HeaderNewPT";
import ImprimerValidateur from "../HeaderNewPT/BoutonsHeader/Validateur/Imprimer/ImprimerValidateur";

import AddValidateur from "../HeaderNewPT/BoutonsHeader/Validateur/AddValidateur/AddValidateur";
import { useParams } from "react-router-dom";
import { getPt } from "../../../../Redux/actions/pt.actions";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "../../../../Utils/utils";
import "./VisaNonSigne/VisaNonSigne.css";
import CardVSNPT from "./VisaNonSigne/CardVSN/CardVSN";
import "./VisaSigne/VisaSigne.css";
import CardVS from "./VisaSigne/CardVS/CardVS";
import { getContacts } from "../../../../Redux/actions/contacts.actions";
import { getEntreprise } from "../../../../Redux/actions/entreprise.actions";
import NewPTContent from "../NewPTContent";

const Validation = () => {
    const { id } = useParams();

    const [loading, setLoading] = useState(true);
    const userReducer = useSelector((state) => state.userReducer);
    const ptReducer = useSelector((state) => [state.ptReducer]);
    const [pt, setPt] = useState({});
    const [eu, setEu] = useState({});
    const [ee, setEe] = useState({});
    const [es, setEs] = useState({});
    const [done, setDone] = useState(false);
    const [validateurs, setValidateurs] = useState({});
    const dispatch = useDispatch();

    const fetchPt = async () => {
        if (userReducer.id !== undefined) {
            const id_usine = userReducer.id_hash_usine;
            await dispatch(getPt(id_usine));
            setLoading(false);
            await dispatch(getEntreprise());
        }
    };

    const setValid = () => {
        if (!isEmpty(ptReducer[0])) {
            ptReducer[0].map((thePt) => {
                if (thePt.id.toString() === id) {
                    setPt(thePt);
                    const tmp = JSON.parse(thePt.validateur_PT);
                    setEu(JSON.parse(thePt.entreprise_utilisatrice_PT));
                    setEe(JSON.parse(thePt.entreprise_exterieure_PT));
                    setEs(JSON.parse(thePt.entreprise_ST_PT));
                    let array = [];
                    for (const key in tmp) {
                        array.push(tmp[key]);
                    }
                    setValidateurs(tmp);
                }
            });
        }
        setDone(true);
    };

    const handleSign = (newValidators) => {
        setValidateurs(newValidators);
    };

    //faire que quand on signe on bloque la modif du pt et changer signe a 1 et numerique a 0 pour les validateurs en question ajouter la date aussi

    useEffect(() => {
        if (loading) {
            fetchPt();
            dispatch(getContacts());
        } else {
            if (!done) {
                setValid();
            }
        }
    }, [ptReducer, loading, done]);

    return (
        <div>
            <Navbar />
            <NavbarNewPT id={id} />
            <HeaderNewPT titre="Validation" />
            <ImprimerValidateur pt={pt} validateurs={validateurs} />
            <AddValidateur titreBtn="Validateur" id={id} eu={eu} ee={ee} es={es} pt={pt} />
            <NewPTContent>
                <div className="content_vnspt">
                    <div className="titre_vnspt">VISA À SIGNER</div>
                    <div className="display_vnspt">
                        {!loading &&
                            done &&
                            validateurs &&
                            Object.entries(validateurs).map(([key, valid]) => {
                                if (valid.prenom !== "" && valid.nom !== "" && valid.signe === 0) {
                                    return (
                                        <CardVSNPT
                                            valid={valid}
                                            pt={pt}
                                            fin={false}
                                            validKey={key}
                                            onSign={handleSign}
                                        />
                                    );
                                }
                            })}
                    </div>
                </div>

                <div className="content_vspt">
                    <div className="titre_vnspt">VISA SIGNÉ</div>
                    <div className="display_vspt">
                        {!loading &&
                            done &&
                            validateurs &&
                            Object.entries(validateurs).map(([key, valid]) => {
                                if (valid.prenom !== "" && valid.nom !== "" && valid.signe === 1) {
                                    return <CardVS valid={valid} />;
                                }
                            })}
                    </div>
                </div>
            </NewPTContent>
        </div>
    );
};

export default Validation;

import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

function AddCommentFC({pt}) {
    const [modal, setModal] = useState(false);

    const [commentaire, setCommentaire] = useState('');

    const toggleModalAddCommentFC = () => { 
        setModal(!modal);
    };

    const handleSubmit = async () => {
        if(pt.commentaires !== null) {
            let comms = JSON.parse(pt.commentaires);
            let length = Object.keys(comms)+1;
            let name = "commentaire"+length;
            let object = comms;
            let objectComm = {
                date : new Date().toLocaleDateString('fr-FR', {weekday: 'short', day : "2-digit", month: 'long', year : "numeric"}),
                texte : commentaire
            }
            object[name] = objectComm;

            await axios({
                method: "put",
                url: `${process.env.REACT_APP_API_URL}/updateCommentaires/${pt.id}`,
                data: object,
                withCredentials: true
            })
                .then(() => {
                    alert('Message sauvegardé');
                    window.location = window.location;
                })
                .catch((err) => {
                    console.log(err);
                    alert('Erreur dans la sauvegarde');
                })
        } else {
            let object = {};
            let objectComm = {
                date : new Date().toLocaleDateString('fr-FR', {weekday: 'short', day : "2-digit", month: 'long', year : "numeric"}),
                texte : commentaire
            }
            object.commentaire1 = objectComm;

            await axios({
                method: "put",
                url: `${process.env.REACT_APP_API_URL}/updateCommentaires/${pt.id}`,
                data: object,
                withCredentials: true
            })
                .then(() => {
                    alert('Message sauvegardé');
                    window.location = window.location;
                })
                .catch((err) => {
                    console.log(err);
                    alert('Erreur dans la sauvegarde');
                })
        }
    }

    if(modal) {
        document.body.classList.add('active-modal')
        
    } else {
        document.body.classList.remove('active-modal')
    }

    const [prenom, setPrenom] = useState('');
    

    let date = new Date().toLocaleDateString('fr-FR', {weekday: 'short', day : "2-digit", month: 'long', year : "numeric"});
    

    return(
        <div>
            <button onClick={toggleModalAddCommentFC} className="button_add_validateur">
                Ajouter un commentaire
            </button>


            {modal && (
                <div className="AddValidateur_overlay">
                    <div className="AddValidateur_wrapper">
                        <div className="AddValidateur_modal">
                            <div className="AddValidateur_header">
                                Nouveau commentaire
                                <button
                                    type="button"
                                    className="modal-close-button"
                                    onClick={toggleModalAddCommentFC}
                                >
                                    X
                                </button>
                            </div>
                            <div className="AddValidateur_content">
                                <h5>{date}</h5>
                                <textarea 
                                    type="text" 
                                    placeholder="Écrire ici votre commentaire" 
                                    style={{width: '400px', height: '400px', border: 'none', borderRadius: '5px',marginTop:'20px', backgroundColor: '#f5f5f5'}}
                                    onChange={(e) => setCommentaire(e.target.value)}
                                    >

                                </textarea>
                            </div>
                            <button
                                type="button"
                                className="modal_button_imprimer_validateur"
                                onClick={handleSubmit}
                            >Ajouter le commentaire</button>
                        </div>
                    </div>
                    
                    
                </div>
            )}
        </div>
    )
}

export default AddCommentFC;
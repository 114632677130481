import axios from 'axios';

export const GET_ENTREPRISE = "GET_ENTREPRISE";

export const getEntreprise = () => {
    return (dispatch) => {
        return axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}/entreprise`,
            withCredentials: true
        })
            .then((res) => {
                dispatch({type : GET_ENTREPRISE, payload : res.data})
            })
            .catch((err) => {console.log(err)});
    }
}
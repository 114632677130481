import React, { useEffect, useState } from "react";
import "./Documents.css";
import Navbar from "../../NavBar/Navbar";
import NavbarNewPT from "../NavbarNewPT/NavbarNewPT";
import HeaderNewPT from "../HeaderNewPT/HeaderNewPT";
import DocGenerate from "./DocGenerate/DocGenerate";
import AddDocument from "../HeaderNewPT/BoutonsHeader/AddDocument/AddDocument";
import { useParams } from "react-router-dom";
import MenuContextuelDoc from "../../MenuContextuel/Documents/MenuContextuelDoc";
import { useDispatch, useSelector } from "react-redux";
import { getPt } from "../../../../Redux/actions/pt.actions";
import { isEmpty } from "../../../../Utils/utils";
import CardDocs from "./CardDocs";
import axios from "axios";
import NewPTContent from "../NewPTContent";
import printJS from "print-js";
import { PDFDocument } from "pdf-lib";
import { saveAs } from "file-saver";

const Documents = () => {
    const { id } = useParams();
    const [showMultiple, setShowMultiple] = useState(false);

    const toggleButtonMultipleSelect = () => {
        setShowMultiple(!showMultiple);
    };

    const [loading, setLoading] = useState(true);
    const [done, setDone] = useState(false);
    const [pt, setPt] = useState({});
    const [documents, setDocuments] = useState({});
    const ptReducer = useSelector((state) => [state.ptReducer]);
    const userReducer = useSelector((state) => state.userReducer);

    const dispatch = useDispatch();

    const fetchPt = async () => {
        if (userReducer.id !== undefined) {
            const id_usine = userReducer.id_hash_usine;
            await dispatch(getPt(id_usine));
            setLoading(false);
        }
    };

    const setValid = () => {
        if (!isEmpty(ptReducer[0])) {
            ptReducer[0].map((thePt) => {
                if (thePt.id.toString() === id) {
                    setPt(thePt);
                    if (thePt.documents_PT !== null && thePt.documents_PT !== "") {
                        setDocuments(JSON.parse(thePt.documents_PT));
                    }
                }
            });
        }
        setDone(true);
    };

    const checkDocument = (id, value) => {
        const newDocuments = documents;
        newDocuments[id].checked = value;
        setDocuments(newDocuments);
    };

    useEffect(() => {
        if (loading) {
            fetchPt();
        } else {
            if (!done) {
                setValid();
            }
        }
    }, [ptReducer, loading, done, userReducer]);

    const printFile = (data) => {
        const blob = new Blob([data], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);
        //window.open(url);
        printJS({
            printable: url,
            type: "pdf",
            showModal: true,
        });
    };

    const printMultiple = async (pdfsToMerge) => {
        /* Array of pdf urls */

        const mergedPdf = await PDFDocument.create();
        for (const pdfCopyDoc of pdfsToMerge) {
            const pdfBytes = await fetch(pdfCopyDoc).then((res) => res.arrayBuffer());
            //const pdfBytes = fs.readFileSync(pdfCopyDoc);
            const pdf = await PDFDocument.load(pdfBytes);
            const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
            copiedPages.forEach((page) => {
                mergedPdf.addPage(page);
            });
        }
        const mergedPdfFile = await mergedPdf.save();
        return mergedPdfFile;
        // downloadFile(mergedPdfFile);
    };

    const handlePrint = async (doc) => {
        await axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}/docs/download/${doc.filename}`,
            responseType: "arraybuffer",
        })
            .then((res) => {
                let filename = doc.filename;
                if (filename.includes(".pdf")) {
                    const blob = new Blob([res.data], {
                        type: "application/pdf",
                    });

                    const blobURL = URL.createObjectURL(blob);

                    printJS({ printable: blobURL, type: "pdf", showModal: true });
                } else {
                    alert("Vous ne pouvez pas imprimer une image, passez par un PDF svp");
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getAndMergePdfs = async () => {
        const printPromises = Object.values(documents)
            .filter((doc) => {
                return doc.checked;
            })
            .map((doc) => {
                return axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}/docs/download/${doc.filename}`,
                    responseType: "arraybuffer",
                });
            });

        const values = await Promise.all(printPromises);
        const pdfValues = values.map((value) => {
            return URL.createObjectURL(new Blob([value.data], { type: "application/pdf" }));
        });
        const mergedPDF = printMultiple(pdfValues);
        return mergedPDF;
    };

    const handleDeleteMultiple = async () => {
        const newDocuments = JSON.parse(JSON.stringify(documents));
        Object.entries(documents).forEach(([key, value]) => {
            if (value.checked) {
                delete newDocuments[key];
            }
        });
        Object.values(documents)
            .filter((doc) => {
                return doc.checked;
            })
            .forEach((document) => {
                axios({
                    method: "delete",
                    url: `${process.env.REACT_APP_API_URL}/docs/${id}`,
                    data: { filename: document.filename, docs: newDocuments },
                    withCredentials: true,
                })
                    .then((res) => {})
                    .catch((err) => {
                        console.log(err);
                    });
            });
        setDocuments(newDocuments);
    };

    const handlePrintMultiple = async (e) => {
        const mergedPDF = await getAndMergePdfs();
        printFile(mergedPDF);
    };

    const handleDownloadMultiple = async () => {
        Object.values(documents)
            .filter((doc) => {
                return doc.checked;
            })
            .forEach((document) => {
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}/docs/download/${document.filename}`,
                    responseType: "arraybuffer",
                })
                    .then((res) => {
                        let filename = document.filename;
                        if (filename.includes(".pdf")) {
                            const blob = new Blob([res.data], {
                                type: "application/pdf",
                            });
                            saveAs(blob, filename);
                        } else if (filename.includes(".jpg")) {
                            const blob = new Blob([res.data], {
                                type: "image/jpeg",
                            });
                            saveAs(blob, filename);
                        } else if (filename.includes(".png")) {
                            const blob = new Blob([res.data], {
                                type: "image/png",
                            });
                            saveAs(blob, filename);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            });
    };
    const handleRename = async (filename, newName) => {};

    return (
        <div>
            <Navbar />
            <NavbarNewPT id={id} />
            <HeaderNewPT titre="Documents" />

            {showMultiple ? (
                <div>
                    <button className="button_docs docs_supprimer" onClick={handleDeleteMultiple}>
                        Supprimer
                    </button>
                    <button className="button_docs docs_telecharger" onClick={handleDownloadMultiple}>
                        Télécharger
                    </button>
                    <button className="button_docs docs_print" onClick={handlePrintMultiple}>
                        Imprimer
                    </button>
                    <button className="button_docs docs_quitter" onClick={toggleButtonMultipleSelect}>
                        Quitter
                    </button>
                </div>
            ) : (
                <div>
                    <button className="button_add_qrcode" onClick={toggleButtonMultipleSelect}>
                        Séléction multiple
                    </button>
                    <AddDocument pt={pt} />
                </div>
            )}

            <NewPTContent>
                <table className="table arrayDocHeader" style={{ width: "90%" }}>
                    <tr className="table-active">
                        <td style={{ width: "20%", textAlign: "center" }}>NOM</td>
                        <td style={{ width: "20%", marginLeft: "2.5%", textAlign: "center" }}>DATE D'AJOUT</td>
                        <td style={{ width: "20%", marginLeft: "2.5%", textAlign: "center" }}>FORMAT</td>
                        <td style={{ width: "20%", marginLeft: "2.5%", textAlign: "center" }}>ACTIONS</td>
                    </tr>
                </table>

                <div className="titre_vnspt" style={{ marginBottom: "15px" }}>
                    AJOUTÉS
                </div>

                {!loading &&
                    documents !== null &&
                    Object.keys(documents).map((doc) => {
                        if (documents[doc].genere === false) {
                            return (
                                <CardDocs
                                    key={doc}
                                    document={documents[doc]}
                                    documents={documents}
                                    displayCheckbox={showMultiple}
                                    onCheck={(value) => {
                                        checkDocument(doc, value);
                                    }}
                                    isChecked={documents[doc].checked}
                                />
                            );
                        }
                    })}

                <div className="titre_vnspt" style={{ marginBottom: "15px" }}>
                    GÉNÉRÉS
                </div>

                {!loading &&
                    documents !== null &&
                    Object.keys(documents).map((doc) => {
                        const userHabilitation = JSON.parse(userReducer.habilitation);
                        const hasHabilitation =
                            documents[doc].habilitation === undefined
                                ? true
                                : userHabilitation[documents[doc].habilitation];

                        if (documents[doc].genere === true) {
                            return (
                                <CardDocs
                                    document={documents[doc]}
                                    documents={documents}
                                    isGenerated={true}
                                    hasHabilitation={hasHabilitation}
                                />
                            );
                        }
                    })}
            </NewPTContent>
        </div>
    );
};

export default Documents;

import axios from "axios";


export const GET_FAVORIS = "GET_FAVORIS";
export const ADD_FAVORIS = "ADD_FAVORIS";
export const REMOVE_FAVORIS = "REMOVE_FAVORIS";

export const getFavoris = () => {
    return (dispatch) => {
        return axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}/favoris`,
            withCredentials: true
        })
            .then((res) => {
                dispatch({type: GET_FAVORIS, payload : res.data})
            })
            .catch((err) => console.log(err));
    }
}

export const addFavoris = (pt_id, user_id) => {
    return (dispatch) => {
        return axios({
            method: "put",
            url: `${process.env.REACT_APP_API_URL}/add-favoris/${pt_id}`,
            data : {id : user_id},
            withCredentials: true
        })
            .then((res) => {
                console.log('done');
            })
            .catch((err) => console.log(err));
    }
}

export const removeFavoris = (pt_id, user_id) => {
    return (dispatch) => {
        return axios({
            method: "put",
            url: `${process.env.REACT_APP_API_URL}/remove-favoris/${pt_id}`,
            data : {id : user_id},
            withCredentials: true
        })
            .then((res) => {
                console.log('done');
            })
            .catch((err) => console.log(err));
    }
}
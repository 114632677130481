import React from "react";
import "./ComponentToPrint.css";
import { Sommaire } from "./Sommaire/Sommaire";
import { DescriptionPDF } from "./Description/DescriptionPDF";
import { RecommandationsPDF } from "./Recommandations/Recommandations";
import { AnalyseRisquePDF } from "./AnalyseRisque/AnalyseRisque";
import { ValidationPDF } from "./Validation/Validation";
import { FinChantierPDF } from "./FinChantier/FinChantier";
import { IntervenantsPDF } from "./Intervenants/Intervenantz";

// Using a class component, everything works without issue
export class ComponentToPrintV extends React.PureComponent {
    render() {
        return (
            <div className="PDFValidateurContent PDFContentPT">
                <Sommaire />

                <DescriptionPDF />

                <RecommandationsPDF
                    avant_intervention={this.props.avant_intervention}
                    mode_operatoire={this.props.mode_operatoire}
                    orga_secours={this.props.orga_secours}
                />

                <AnalyseRisquePDF risques={this.props.risques} />

                <ValidationPDF />
                <IntervenantsPDF />
                <FinChantierPDF />
            </div>
        );
    }
}

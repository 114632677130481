import React from "react";
import "./Sommaire.css";
import PDFHeader from "../../PDFHeader/PDFHeader";
import { QRCodeCanvas } from "qrcode.react";

// Using a class component, everything works without issue
export class Sommaire extends React.PureComponent {
    render() {
        return (
            <div className="PDFValidateurContent">
                <PDFHeader
                    ptId={this.props.pt.id}
                    numPermis={this.props.pt !== undefined ? this.props.pt.numero_PT : ""}
                    prenom={this.props.eu.contact_RdP !== undefined ? this.props.eu.contact_RdP.prenomRdP : ""}
                    nom={this.props.eu.contact_RdP !== undefined ? this.props.eu.contact_RdP.nomRdP : ""}
                />
                <div className="sommairePDF">
                    <h1>PLAN DE TRAVAIL</h1>
                    <h2>SOMMAIRE</h2>
                    <div className="d-flex align-items-center justify-content-evenly">
                        <div>
                            <h3>I - DESCRIPTION</h3>
                            <h3>II - RECOMMANDATIONS</h3>
                            <h3>III - ANALYSE DES RISQUES</h3>
                            <h3>IV - VALIDATION</h3>
                            <h3>V - FIN DE CHANTIER</h3>
                            {/* <h3>VI - DOCUMENTS</h3> */}
                        </div>
                    </div>
                </div>
                {this.props.pt.id_usine ===
                "4e0149a8e906e9844fd662f20e414e04b729d299632ce78a3d31873b56a36c069e052374249025c138db62605167518fb3bfa888d269754475e93fd0a08f03ce" ? (
                    <div>
                        <h4 className="qrcode">Lien vers le questionnaire visiteur exterieur</h4>
                        <div className="qrcode">
                            <QRCodeCanvas
                                value={
                                    "https://docs.google.com/forms/d/e/1FAIpQLSeSODkpJERUg3LAftokNa2xE-N-c_dCgoKY64p-8en76r4udw/viewform?usp=sf_link"
                                }
                                size={80}
                            />
                        </div>
                    </div>
                ) : (
                    <div />
                )}
                <div className="pagebreak"></div>
            </div>
        );
    }
}

import React from "react";
import "./FailedLoginModal.css";
import { Await } from "react-router";

function ResetCookiePt (){
    const cookies = document.cookie;
    document.cookie = "pt=; expires=Thu, 01 Jan 1970 00:00:00 GMT";
    console.log('deleting cookie')
    window.location.reload(false);
}

export function FailedLoginModal({refused}){
    console.log(refused)
    const styleoverlaylogin = !refused ? 'none' : 'block';
    const stylemodallogin = !refused ? 'none' : 'flex';  
return(
< div>
    <div className="overlayfailedlogin" style={{display: styleoverlaylogin}}/>
    <div className="modal" style={{display: stylemodallogin}}>
        <div className="textfailedlogin"> Un utilisateur a refusé l'utilisation des données personnelles, ce qui empêche l'utilisation de l'application sur cet appareil.<br></br><br></br> Cliquez sur le bouton "Réinitialiser" pour réinitialiser cette décision<br></br><br></br>Sinon vous pouvez fermer cette page</div>
        <div className="bouttonwrapper"><button className="ResetRGPD" onClick={ResetCookiePt}>Réinitialiser</button></div> 
    </div>
</div>
);
}

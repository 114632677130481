import React, { useEffect, useState } from "react";
import "./AddValidateur.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useSelector } from "react-redux";
import { isEmpty } from "../../../../../../../Utils/utils";

function AddValidateur({ titreBtn, id, eu, ee, es, pt }) {
    const [modal, setModal] = useState(false);

    const [validAutocomplete, setValidAutocomplete] = useState([]);
    const [euEntreprise, setEuEntreprise] = useState({});
    const [eeEntreprise, setEeEntreprise] = useState({});
    const [esEntreprise, setEsEntreprise] = useState({});

    const contactsReducer = useSelector((state) => state.contactsReducer);
    const entrepriseReducer = useSelector((state) => state.entrepriseReducer);

    const toggleModalAddValidateur = () => {
        setModal(!modal);
        setEntreprise("");
    };

    if (modal) {
        document.body.classList.add("active-modal");
    } else {
        document.body.classList.remove("active-modal");
    }

    const handleAddIntervenant = () => {
        let data = {};

        if (entreprise === "EE") {
            data = {
                prenom: prenom,
                nom: nom,
                mail: mail,
                tel: tel,
                entreprise: ee.nom_EE_PT,
                id_entreprise: eeEntreprise,
                id_hash_usine: "",
            };
        } else if (entreprise === "ES") {
            data = {
                prenom: prenom,
                nom: nom,
                mail: mail,
                tel: tel,
                entreprise: es.nom_ST_PT,
                id_entreprise: esEntreprise,
                id_hash_usine: "",
            };
        } else {
            data = {
                prenom: prenom,
                nom: nom,
                mail: mail,
                tel: tel,
                entreprise: eu.nom_EU_PT,
                id_entreprise: "",
                id_hash_usine: euEntreprise,
            };
        }

        axios({
            method: "put",
            url: `${process.env.REACT_APP_API_URL}/addValidateur/${id}`,
            data: data,
            withCredentials: true,
        })
            .then(() => {
                alert("Validateur ajouté");
                window.location = window.location;
            })
            .catch((err) => {
                console.log(err);
                alert("Erreur dans l'ajout du validateur");
            });
    };

    const onChangeHandler = () => {
        if (entreprise === "EU") {
            let matches = [];
            matches = contactsReducer.filter((user) => {
                if (user.id_hash_usine === euEntreprise) {
                    const regex = new RegExp(`^${prenom}`, "gi");
                    if (user.prenom.match(regex)) {
                        return user;
                    }
                }
            });
            setValidAutocomplete(matches);
        } else if (entreprise === "EE") {
            let matches = [];
            matches = contactsReducer.filter((user) => {
                if (user.id_entreprise === eeEntreprise) {
                    const regex = new RegExp(`${prenom}`, "gi");
                    if (user.prenom.match(regex)) {
                        return user;
                    }
                }
            });
            setValidAutocomplete(matches);
        } else if (entreprise === "ES") {
            let matches = [];
            matches = contactsReducer.filter((user) => {
                if (user.id_entreprise === esEntreprise) {
                    const regex = new RegExp(`^${prenom}`, "gi");
                    if (user.prenom.match(regex)) {
                        return user;
                    }
                }
            });
            setValidAutocomplete(matches);
        }
    };

    const [prenom, setPrenom] = useState("");
    const [nom, setNom] = useState("");
    const [tel, setTel] = useState("");
    const [mail, setMail] = useState("");
    const [entreprise, setEntreprise] = useState("");

    const handleChangePrenom = (e) => {
        setPrenom(e.target.value);
        onChangeHandler();
    };

    const handleSelectedValid = (contact) => {
        console.log(contact);
        document.getElementById("prenom").value = contact.prenom;
        document.getElementById("nom").value = contact.nom;
        document.getElementById("tel").value = contact.tel;
        document.getElementById("mail").value = contact.mail;
        setPrenom(contact.prenom);
        setNom(contact.nom);
        setTel(contact.tel);
        setMail(contact.mail);

        setValidAutocomplete([]);
    };
    const userReducer = useSelector((state) => state.userReducer);
    useEffect(() => {
        if (!isEmpty(entrepriseReducer[0])) {
            entrepriseReducer.map((entreprise) => {
                if (entreprise.raison_sociale.localeCompare(ee.nom_EE_PT, undefined, { sensitivity: "base" }) === 0) {
                    // if (entreprise.raison_sociale === ee.nom_EE_PT) {
                    setEeEntreprise(entreprise.id_hash);
                }
                if (entreprise.raison_sociale.localeCompare(es.nom_ST_PT, undefined, { sensitivity: "base" }) === 0) {
                    // if (entreprise.raison_sociale === es.nom_ST_PT) {
                    setEsEntreprise(entreprise.id_hash);
                } else {
                    setEuEntreprise(userReducer.id_hash_usine);
                }
            });
        }
    }, [entrepriseReducer]);

    return (
        <div>
            <button onClick={toggleModalAddValidateur} className="button_add_validateur">
                <FontAwesomeIcon icon={faCirclePlus} />
                Ajouter un {titreBtn}
            </button>

            {modal && (
                <div className="AddValidateur_overlay">
                    <div className="AddValidateur_wrapper">
                        <div className="AddValidateur_modal">
                            <div className="AddValidateur_header">
                                Nouveau {titreBtn}
                                <button type="button" className="modal-close-button" onClick={toggleModalAddValidateur}>
                                    X
                                </button>
                            </div>
                            <div className="AddValidateur_content">
                                <div style={{ marginTop: "20px" }} className="d-flex">
                                    <p>Employé chez : </p>
                                    <div className="form-check form-check-inline" style={{ marginLeft: "20px" }}>
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="ent"
                                            id="inlineCheckbox1"
                                            onClick={() => setEntreprise("EU")}
                                            value="option1"
                                        />
                                        <label className="form-check-label" htmlFor="inlineCheckbox1">
                                            EU
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="ent"
                                            id="inlineCheckbox2"
                                            onClick={() => setEntreprise("EE")}
                                            value="option2"
                                        />
                                        <label className="form-check-label" htmlFor="inlineCheckbox2">
                                            EE
                                        </label>
                                    </div>
                                    {es.nom_ST_PT !== undefined && (
                                        <div className="form-check form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="ent"
                                                id="inlineCheckbox2"
                                                onClick={() => setEntreprise("ES")}
                                                value="option2"
                                            />
                                            <label className="form-check-label" htmlFor="inlineCheckbox2">
                                                ES
                                            </label>
                                        </div>
                                    )}
                                </div>
                                <input
                                    className="AddValidateur_content_input"
                                    type="text"
                                    id="prenom"
                                    onChange={(e) => handleChangePrenom(e)}
                                    placeholder="Prénom"
                                    autoComplete="off"
                                    onBlur={() => {
                                        setTimeout(() => {
                                            setValidAutocomplete([]);
                                        }, 100);
                                        
                                    }}
                                    disabled={(entreprise=="") ? true: false}
                                />
                                <div
                                    style={{
                                        position: "fixed",
                                        backgroundColor: "white",
                                        width: "25%",
                                        marginTop: "100px",
                                    }}
                                >
                                    {validAutocomplete &&
                                        validAutocomplete.map(
                                            (suggest, i) =>
                                                i < 14 && (
                                                    <div
                                                        key={i}
                                                        className="suggest"
                                                        style={{ width: "50%", marginLeft: "13%" }}
                                                        onClick={() => handleSelectedValid(suggest)}
                                                    >
                                                        {suggest.prenom} {suggest.nom}
                                                    </div>
                                                )
                                        )}
                                </div>
                                <input
                                    className="AddValidateur_content_input"
                                    type="text"
                                    id="nom"
                                    onChange={(e) => setNom(e.target.value)}
                                    placeholder="Nom"
                                    disabled={(entreprise=="") ? true: false}
                                />
                                <input
                                    className="AddValidateur_content_input"
                                    type="text"
                                    id="tel"
                                    onChange={(e) => setTel(e.target.value)}
                                    placeholder="Téléphone"
                                    disabled={(entreprise=="") ? true: false}
                                />
                                <input
                                    className="AddValidateur_content_input"
                                    type="text"
                                    id="mail"
                                    onChange={(e) => setMail(e.target.value)}
                                    placeholder="Email"
                                    disabled={(entreprise=="") ? true: false}

                                />
                            </div>
                            <button
                                type="button"
                                className="modal_button_imprimer_validateur"
                                onClick={handleAddIntervenant}
                            >
                                Ajouter le nouveau validateur
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default AddValidateur;

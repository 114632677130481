import {GET_PT} from "../actions/pt.actions";

const initialState = {};

export default function ptReducer( state = initialState, action) {

    switch (action.type) {
        case GET_PT:
            return action.payload;

        default:
            return state;
    }
}
import React, { useRef, useState, useContext, useEffect } from "react";
import AuthContext from "../Context/AuthProvider";
import LeftLogin from "./DesignLogin/LeftLogin";
import logoTop from "../../assets/images/logo/logowhite2.png";
import logoBot from "../../assets/images/logo/logowhite1.png";

import axios from "axios";
import { sha512 } from "js-sha512";
import { Buffer } from "buffer";
import userReducer from "../../Redux/reducers/user.reducer";
import { useSelector } from "react-redux";

function ChangePassword() {
    const [actualPass, setActualPass] = useState("");
    const [newPass, setNewPass] = useState("");
    const [confirmPass, setConfirmPass] = useState("");
    const [wrongActual, setWrongActual] = useState("");
    const [wrongConfirm, setWrongConfirm] = useState("");

    const encodePass = (pass) => {
        const dataSha512 = sha512("qs" + pass + "38FJEu");
        let buff = new Buffer.from(dataSha512);
        let passwordEncode = buff.toString("base64");
        return passwordEncode;
    };

    const userReducer = useSelector((state) => state.userReducer);

    const handleSubmit = async (e) => {
        e.preventDefault();

        setWrongActual("");
        setWrongConfirm("");

        const actual = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}/actual`,
            data: {
                password: encodePass(actualPass),
                id: userReducer.id,
            },
            withCredentials: true,
        }).then(async (res) => {
            if (res.data === true) {
                if (newPass === confirmPass) {
                    await axios({
                        method: "post",
                        url: `${process.env.REACT_APP_API_URL}/changePass`,
                        data: { password: encodePass(newPass), id: userReducer.id },
                        withCredentials: true,
                    }).then((res) => {
                        if (res.data === true) {
                            alert("Mot de passe modifié avec succès");
                        } else {
                            alert("Erreur du serveur, impossible de modifié le mot de passe");
                        }
                    });
                } else {
                    setWrongConfirm("Les mots de passe ne correspondent pas");
                }
            } else {
                setWrongActual("Le mot de passe ne correspond pas avec l'ancien");
            }
        });
    };

    return (
        <section className="update-profile-settings-section">
            <h1>Nouveau Mot de Passe</h1>
            <form onSubmit={handleSubmit} className="d-flex flex-column" style={{ width: "80%", marginTop: "20px" }}>
                <input
                    placeholder="Ancien mot de passe"
                    type="password"
                    style={{ marginBottom: "8px", borderRadius: "5px", width: "100%", padding: "20px" }}
                    onChange={(e) => setActualPass(e.target.value)}
                />
                <p style={{ color: "red" }}>{wrongActual}</p>
                <input
                    placeholder="Nouveau mot de passe"
                    type="password"
                    style={{ marginBottom: "8px", borderRadius: "5px", width: "100%", padding: "20px" }}
                    onChange={(e) => setNewPass(e.target.value)}
                />
                <input
                    placeholder="Valider le mot de passe"
                    type="password"
                    style={{ marginBottom: "8px", borderRadius: "5px", width: "100%", padding: "20px" }}
                    onChange={(e) => setConfirmPass(e.target.value)}
                />
                <p style={{ color: "red" }}>{wrongConfirm}</p>
                <button
                    style={{
                        backgroundColor: "#1d5399",
                        padding: "20px",
                        color: "white",
                        border: "none",
                        borderRadius: "5px",
                    }}
                    type="submit"
                >
                    Confirmer votre nouveau Mot de Passe
                </button>
            </form>
        </section>
    );
}

export default ChangePassword;

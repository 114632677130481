import React, { Component, useEffect, useState } from "react";
import "./Bandeau.css";
import Deconnexion from "../../Deconnexion/Deconnexion";
import logoTop from "../../../assets/images/logo/logowhite2.png";
import random_user from "../../../assets/images/logo/random_user.png";
import logoBot from "../../../assets/images/logo/logowhite1.png";
import { useSelector } from "react-redux";
import { buildProfilePictureUrl } from "../../../Utils/utils";

function Accueil() {
    const userReducer = useSelector((state) => state.userReducer);
    const [photo, setPhoto] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (loading) {
            if (loading) {
                if (userReducer.id !== undefined) {
                    if (userReducer.photo !== null && userReducer.photo !== undefined && userReducer.photo !== "") {
                        setPhoto(userReducer.photo);
                    }

                    setLoading(false);
                }
            }
        }
    }, [userReducer, loading]);

    return (
        <div className="bandeau">
            <div className="top">
                <div className="logo">
                    <div className="d-flex flex-column">
                        <img src={logoTop} alt="logo" />
                        <img src={logoBot} alt="logo" />
                    </div>
                </div>
                <div className="avatar">
                    {!loading && (
                        <>
                            <img
                                src={photo !== "" ? buildProfilePictureUrl(photo) : random_user}
                                alt="random_user"
                                style={{ borderRadius: 20, height: 40, width: 40 }}
                            />
                            <h3>Bonjour {userReducer.prenom}</h3>
                        </>
                    )}
                </div>

                <Deconnexion />
            </div>
        </div>
    );
}

export default Accueil;

import React from "react";
import "./ComponentToPrint.css";
import PDFHeader from "../PDFHeader/PDFHeader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignature } from "@fortawesome/free-solid-svg-icons";
import { faFileSignature } from "@fortawesome/free-solid-svg-icons";
import { faX } from "@fortawesome/free-solid-svg-icons";

// Using a class component, everything works without issue
export class ComponentToPrintDocusign extends React.PureComponent {



    render() {
        return (
            <div className="PDFValidateurContent">
                <PDFHeader
                    numPermis={this.props.pt.numero_PT}
                    prenom={this.props.eu.contact_RdP.prenomRdP}
                    nom={this.props.eu.contact_RdP.nomRdP}
                />


                <div className="ArrayValidateur">
                    <table className="PDFTable">
                        <thead className="PDFHeader">
                        <tr className="PDFTableTR">
                            <th className="PDFTableTH">Validateur</th>
                            <th className="PDFTableTH">Date</th>
                            <th  className="PDFTableTH">Signature*</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.props.validateurs !== null &&
                            Object.keys(this.props.validateurs).map((validateur) => {
                                if(this.props.validateurs[validateur].signe === 2) {
                                    return <tr className="PDFTableTR">
                                        <th className="PDFTableTH" scope="row">{this.props.validateurs[validateur].prenom} {this.props.validateurs[validateur].nom}</th>
                                        <td className="PDFTableTD">{new Date().toLocaleString('default', {weekday: 'short', day : "2-digit", month: 'long', year : "numeric"})}</td>
                                        <td className="PDFTableTD" style={{color : "white"}}>/sn1/</td>
                                    </tr>
                                } else if(this.props.validateurs[validateur].signe === 1 && this.props.validateurs[validateur].numerique === 1) {
                                    return <tr className="PDFTableTR">
                                        <th className="PDFTableTH" scope="row">{this.props.validateurs[validateur].prenom} {this.props.validateurs[validateur].nom}</th>
                                        <td className="PDFTableTD">{new Date().toLocaleString('default', {weekday: 'short', day : "2-digit", month: 'long', year : "numeric"})}</td>
                                        <td className="PDFTableTD"><FontAwesomeIcon icon={ faSignature }/></td>
                                    </tr>
                                } else if(this.props.validateurs[validateur].signe === 1 && this.props.validateurs[validateur].numerique === 0) {
                                    return <tr className="PDFTableTR">
                                        <th className="PDFTableTH" scope="row">{this.props.validateurs[validateur].prenom} {this.props.validateurs[validateur].nom}</th>
                                        <td className="PDFTableTD">{new Date().toLocaleString('default', {weekday: 'short', day : "2-digit", month: 'long', year : "numeric"})}</td>
                                        <td className="PDFTableTD"><FontAwesomeIcon icon={ faFileSignature }/></td>
                                    </tr>
                                } else {
                                    return null;
                                }
                            })
                        }
                        </tbody>
                    </table>
                </div>
                <p style={{textAlign:'center', fontSize:'12px'}}>*Seules les lignes n'ayant pas de signatures doivent êtres signées et datées.<br/><FontAwesomeIcon icon={ faSignature }/> : déjà signé numériquement.<br /><FontAwesomeIcon icon={ faFileSignature }/> : déjà signé manuellement<br/><FontAwesomeIcon icon={ faX }/> : ne signe pas maintenant</p>
            </div>
        );
    }
}

import React, { useEffect, useState } from "react";
import "./NavbarNewPT.css";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { isEmpty } from "../../../../Utils/utils";
import { type } from "@testing-library/user-event/dist/type";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faClose } from "@fortawesome/free-solid-svg-icons";

const NavbarNewPt = ({ id, isDirty }) => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const openMenu = () => {
        setMobileMenuOpen(!mobileMenuOpen);
    };

    const [urlDesc, setUrlDesc] = useState("");
    const [urlReco, setUrlReco] = useState("");
    const [urlAnal, setUrlAnal] = useState("");
    const [urlVali, setUrlVali] = useState("");
    const [urlInte, setUrlInte] = useState("");
    const [urlFinC, setUrlFinC] = useState("");
    const [urlDocu, setUrlDocu] = useState("");

    const ptReducer = useSelector((state) => [state.ptReducer]);

    const [numeroPt, setNumeroPt] = useState("");

    useEffect(() => {
        if (id !== undefined) {
            setUrlDesc(`/pt/description/${id}`);
            setUrlReco(`/pt/recommandations/${id}`);
            setUrlAnal(`/pt/analyse-risque/${id}`);
            setUrlVali(`/pt/validation/${id}`);
            setUrlInte(`/pt/intervenants/${id}`);
            setUrlFinC(`/pt/fin-chantier/${id}`);
            setUrlDocu(`/pt/documents/${id}`);
            if (!isEmpty(ptReducer[0])) {
                ptReducer[0].map((pt) => {
                    if (pt.id.toString() === id) {
                        setNumeroPt(pt.numero_PT);
                    }
                });
            }
        }
    }, [id, ptReducer]);

    let navigate = useNavigate();

    const routeChange = (url) => {
        let path = url;
        navigate(path);
        if (isDirty) {
            if (window.confirm("attention vous avez modifié, êtes vous surs de vouloir partir ?")) {
            }
        }
    };

    return (
        <div>
            <>
                <div className={`navbar_newpt ${mobileMenuOpen ? "" : "navbar_newpt_open"}`}>
                    <div className="burger-newpt" onClick={openMenu}>
                        <FontAwesomeIcon icon={mobileMenuOpen ? faClose : faBars} size="lg" color="white" />
                        <span style={{ color: "white", paddingLeft: "8px" }}>{mobileMenuOpen ? "Fermer" : "Menu"}</span>
                    </div>
                    <div className="numero_newpt hiddeable">
                        <p>NUMÉRO DE PERMIS</p>
                        <h5>{id !== "0" && numeroPt}</h5>
                    </div>
                    <div className="content_navbar_newpt hiddeable">
                        {/* <button onClick={routeChange} className="navlink_newpt" >
                            Description
                        </button>
                        <button onClick={routeChange} className="navlink_newpt" >
                            Recommandations
                        </button>
                        <button onClick={routeChange} className="navlink_newpt" >
                            Analyse des risques
                        </button>
                        <button onClick={routeChange} className="navlink_newpt" >
                            Validation
                        </button>
                        <button onClick={routeChange} className="navlink_newpt" >
                            Intervenants
                        </button>
                        <button onClick={routeChange} className="navlink_newpt" >
                            Fin de chantier
                        </button>
                        <button onClick={routeChange} className="navlink_newpt" >
                            Documents
                        </button> */}
                        <NavLink end to={urlDesc} activeclassname="isActive" className="navlink_newpt">
                            Description
                        </NavLink>
                        <NavLink end to={urlReco} className="navlink_newpt">
                            Recommandations
                        </NavLink>
                        <NavLink end to={urlAnal} className="navlink_newpt">
                            Analyse des risques
                        </NavLink>
                        <NavLink end to={urlVali} className="navlink_newpt">
                            Validation
                        </NavLink>
                        <NavLink end to={urlInte} className="navlink_newpt">
                            Intervenants
                        </NavLink>
                        {/* <NavLink end to="/pt/tournee-chantier" className="navlink_newpt">Tournée de chantier</NavLink> */}
                        <NavLink end to={urlFinC} className="navlink_newpt">
                            Fin de chantier
                        </NavLink>
                        <NavLink end to={urlDocu} className="navlink_newpt">
                            Documents
                        </NavLink>
                    </div>
                </div>
            </>
        </div>
    );
};

export default NavbarNewPt;

import React, {useState} from 'react';
import LeftLogin from './DesignLogin/LeftLogin';
import ModifierDate from './BoutonsA/ModifierDate/ModifierDate';
import ArriveeI from './BoutonsA/Actions/Arrivee';
import DepartI from './BoutonsA/Actions/Depart';

function PointageEEA(props) {

    const [date, setDate] = useState(new Date().toLocaleString('en-En', {weekday: 'short', day : "2-digit", month: 'long', year : "numeric"}));

    const handleChangeDate = (date) => {
        setDate(date.toLocaleString('default', {weekday: 'short', day : "2-digit", month: 'long', year : "numeric"}));
    }
    return (
        <div className='d-flex'>
            <LeftLogin />
            <div style={{
                backgroundColor: '#072872',
                width: '50%',
                color: 'white',
                display: "flex",
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <select>
                    <option>Français</option>
                    <option>English</option>
                </select>
                <div className='' style={{marginBottom: '50px', alignItems: 'center'}}>
                    <h2 style={{textAlign: 'center'}}>{date}</h2>
                    <ModifierDate handleChangeDate={handleChangeDate}/>
                </div>
                <div style={{ width: '80%'}}>
                    <h3 style={{marginBottom: '40px'}}>WORKING HOUR CLOCK</h3>
                    <div className='d-flex justify-content-between align-items-center' style={{backgroundColor: '#536a9c', height: '60px', marginBottom: '20px', padding: "20px"}}>
                        <p>Check in</p>
                        <ArriveeI />
                    </div>
                    <div className='d-flex justify-content-between align-items-center' style={{backgroundColor: '#536a9c', height: '60px', marginBottom: '20px', padding: "20px"}}>
                        <p>Check out</p>
                        <DepartI />
                    </div>
                    <div className='d-flex justify-content-between align-items-center' style={{backgroundColor: '#536a9c', height: '60px', marginBottom: '20px', padding: "20px"}}>
                        <p>Break time</p>
                        <input type="text" placeholder="60" style={{width: '24%'}} />
                    </div>
                </div>
                <button style={{backgroundColor: '#536a9c', border: 'none', width: '30%', height: '40px', color: 'white'}}>Close</button>
            </div>
        </div>
    );
}

export default PointageEEA;
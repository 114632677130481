export const hashFactory = {
  "e572fabd53d8db8c9f51b1867374e49e74564ca70bd90188b59eb88eb937733fc7b95a44bad63c6cad565556376415a7902358a7a8331d7afb89bdc0a7646baa":"volvic",
  "a16daba7c955408177b1e2c9fb168f2c5966b6fc26d0349328cbc4c00e780a916ab7413fe7066505b18df9561e2567d70646ad2a494ca479a62f1abddfbafd40":"badoit",
  "4e0149a8e906e9844fd662f20e414e04b729d299632ce78a3d31873b56a36c069e052374249025c138db62605167518fb3bfa888d269754475e93fd0a08f03ce":"salvetat",
  "4dce309800d99d34cfd50003c0c7d1082b6bbb62a4c435adb325c8c3e1ca655744209f77750619f658036cd1fd62dd6c6413d3cd8bfd6f17fb35332cdbc0decb":"evian"
}

export const factoryHash = {
  volvic:"e572fabd53d8db8c9f51b1867374e49e74564ca70bd90188b59eb88eb937733fc7b95a44bad63c6cad565556376415a7902358a7a8331d7afb89bdc0a7646baa",
  badoit:"a16daba7c955408177b1e2c9fb168f2c5966b6fc26d0349328cbc4c00e780a916ab7413fe7066505b18df9561e2567d70646ad2a494ca479a62f1abddfbafd40",
  salvetat:"4e0149a8e906e9844fd662f20e414e04b729d299632ce78a3d31873b56a36c069e052374249025c138db62605167518fb3bfa888d269754475e93fd0a08f03ce",
  evian:"4dce309800d99d34cfd50003c0c7d1082b6bbb62a4c435adb325c8c3e1ca655744209f77750619f658036cd1fd62dd6c6413d3cd8bfd6f17fb35332cdbc0decb"
}
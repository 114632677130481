import React, {useState} from "react";
import "./MenuContextuelAdmin.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from "@fortawesome/free-solid-svg-icons";
import {useDispatch} from "react-redux";
import {setUserInactive} from "../../../../Redux/actions/users.actions";
import UpdateUser from "../../HeaderAdmin/AddUser/UpdateUser";


function MenuContextuel({user, handleShowUpdate}) {
    const [modal, setModal] = useState(false);

    const dispatch = useDispatch();

    const toggleModalMC = () => {
        setModal(!modal);
    };

    const handleSetNotActive = async () => {
        await dispatch(setUserInactive(user.id));
    }

    const handleUpdate = () => {
        handleShowUpdate();
    }

    return(

        <div className="menu_contextuel">

            <button onClick={toggleModalMC} className="bouton_mc">
                <FontAwesomeIcon icon={ faBars } />
            </button>

            {modal && (
                <div className="modal_mc">
                    <div onClick={handleUpdate}>
                        Modifier
                    </div>
                    <div onClick={handleSetNotActive}>
                        Rendre inactif
                    </div>
                </div>
            )}

        </div>
    )}
export default MenuContextuel;


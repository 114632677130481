import React from "react";
import PDFHeader from "../../PDFHeader/PDFHeader";

// Using a class component, everything works without issue
export class CardCommentairePDF extends React.PureComponent {
    render() {
        return (
            <tr>
                <th scope="row">{this.props.commentaire.date}</th>
                <td>{this.props.commentaire.texte}</td>
            </tr>
        );
    }
}

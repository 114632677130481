import axios from "axios";

export const GET_RECO = "GET_RECO";
export const SET_AVANT = "SET_AVANT";
export const SET_MODEOPE = "SET_MODEOPE";
export const SET_ORGASEC = "SET_ORGASEC";

export const getRecommandations = () => {
    return (dispatch) => {
        return axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}/recommandation`,
            withCredentials: true,
        })
            .then((res) => {
                dispatch({ type: GET_RECO, payload: res.data });
            })
            .catch((err) => {
                console.log(err);
            });
    };
};

export const setAvantRecommandation = (id, text) => {
    const trimmedId = id.substring(0, 30);
    return (dispatch) => {
        return axios({
            method: "put",
            url: `${process.env.REACT_APP_API_URL}/avant-inter/${trimmedId}`,
            data: { text },
            withCredentials: true,
        }).then((res) => {
            dispatch({ type: SET_AVANT, payload: { id, text } });
        });
    };
};

export const setModeOpeRecommandation = (id, text) => {
    const trimmedId = id.substring(0, 30);
    return (dispatch) => {
        return axios({
            method: "put",
            url: `${process.env.REACT_APP_API_URL}/mode-ope/${trimmedId}`,
            data: { text },
            withCredentials: true,
        }).then((res) => {
            dispatch({ type: SET_MODEOPE, payload: { id, text } });
        });
    };
};

export const setRecoOrgaSec = (id, orga) => {
    const trimmedId = id.substring(0, 30);
    return (dispatch) => {
        return axios({
            method: "put",
            url: `${process.env.REACT_APP_API_URL}/orga-sec/${trimmedId}`,
            data: { orga },
            withCredentials: true,
        }).then((res) => {
            dispatch({ type: SET_ORGASEC, payload: { id, orga } });
        });
    };
};

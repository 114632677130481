import React, { useEffect, useState } from "react";
import MenuContextuelAdmin from "./MenuContextuelAdmin/MenuContextuelAdmin";
import { useSelector } from "react-redux";
import { isEmpty } from "../../../Utils/utils";
import UpdateUser from "../HeaderAdmin/AddUser/UpdateUser";

const CardUser = ({ user, handleShowUserUpdate }) => {
    const usineReducer = useSelector((state) => state.usineReducer);

    const [habilitations, setHabilitations] = useState([]);

    const handleShowUpdate = () => {
        handleShowUserUpdate(user);
    };

    useEffect(() => {
        if (user.habilitation !== null && user.habilitation !== undefined) {
            setHabilitations(JSON.parse(user.habilitation));
        }
    }, [user]);

    return (
        <tr
            className="tableau_content"
            style={{ marginTop: "10px", height: "auto", backgroundColor: "#F5F5F5", color: "black" }}
        >
            <th style={{ width: "20%", overflow: "hidden", textOverflow: "ellipsis" }}>
                {user.prenom} {user.nom}
            </th>
            <td style={{ width: "20%", overflow: "hidden", textOverflow: "ellipsis" }}>
                {!isEmpty(usineReducer[0]) &&
                    Object.keys(usineReducer).map((key) => {
                        if (usineReducer[key].id_hash === user.id_hash_usine) {
                            return usineReducer[key].raison_sociale;
                        }
                    })}
            </td>
            <td style={{ width: "20%", overflow: "hidden", textOverflow: "ellipsis" }}>
                {user.admin === 1 ? <>ADMIN</> : <>UTILISATEUR</>}
            </td>
            <td style={{ width: "20%", overflow: "hidden", textOverflow: "ellipsis" }}>
                {Object.keys(habilitations).map((habilitation) => {
                    if (habilitations[habilitation] === true) {
                        return (
                            <>
                                {habilitation.replaceAll("permis", "")}
                                <br />
                            </>
                        );
                    }
                })}
            </td>
            <td>
                <MenuContextuelAdmin user={user} handleShowUpdate={handleShowUpdate} />
            </td>
        </tr>
    );
};

export default CardUser;
